import React, { useEffect, useRef, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminTrading.css";
import {
  a1,
  a18,
  a20,
  a22,
  a28,
  a4,
  a40,
  a41,
  a48,
  a49,
  a51,
  a53,
  a56,
  a57,
  a59,
  a61,
  a64,
  a65,
  a66,
  a71,
  a74,
} from "../../../Api/RootApiPath";
import { AiOutlineEdit, AiOutlinePlusSquare } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { RiDeleteBin2Line } from "react-icons/ri";
import { BsCardImage } from "react-icons/bs";
import jsPDF from "jspdf";
import logoImage from "../../../Images/soniJewellersBillTitle.jpg";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineSend } from "react-icons/ai";
import { LiaCartPlusSolid } from "react-icons/lia";
import { MdOutlineLabelOff } from "react-icons/md";
import { numberToIndianWords } from "../../../Other Functions/numberToIndianWords";
import DateTime from "../../../Other Functions/DateTime";
import { FaDollarSign } from "react-icons/fa";
import { FaRegCircle, FaRegDotCircle } from "react-icons/fa";
import { BsImages } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function AdminPurchaseEntry() {
  const [allCsData, setAllCsData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerEdit, setSelectedCustomerEdit] = useState(false);
  //   const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [labelName, setLabelName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProductPrice, setSelectedProductPrice] = useState(0);
  const [barcode, setBarcode] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [productName, setProductName] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [purityType, setPurityType] = useState("");
  const [productQty, setProductQty] = useState("");
  const [allSelectedProducts, setAllSelectedProducts] = useState([]);
  const [showAllFields, setShowAllFields] = useState(false);
  const [allProdctsNetAmount, setAllProdctsNetAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalPayableGstAmount, setTotalPayableGstAmount] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [allProdctsGstAmount, setAllProdctsGstAmount] = useState(0);
  const [productsLoading, setProductsLoading] = useState(true);
  const [updatePrices, setUpdatePrices] = useState(false);
  const [openEditBox, setOpenEditBox] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [orderItemsData, setOrderItemsData] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [orderCsData, setOrderCsData] = useState([]);
  const [oldGoldAmount, setOldGoldAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalPayableGold, setTotalPayableGold] = useState(0);
  const [totalPayableSilver, setTotalPayableSilver] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState("Cash");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [payments, setPayments] = useState([]);
  const [active, setActive] = useState("Purchase");
  const [convertAmount, setConvertAmount] = useState(true);
  const [allCategories, setAllCategories] = useState([]);
  const [allProductTypes, setAllProductTypes] = useState([]);
  const [allPurities, setAllPurities] = useState([]);
  const [allUnlabelList, setAllUnlabelList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedPurity, setSelectedPurity] = useState("");
  const [allSalesTeam, setAllSalesTeam] = useState([]);
  const [selectedSalesEmployee, setSelectedSalesEmployee] = useState("");
  const [selectedCashierEmployee, setSelectedCashierEmployee] = useState("");
  const [paymentType, setPaymentType] = useState("Paid");
  const [paymentGold, setPaymentGold] = useState(0);
  const [deductGold, setDeductGold] = useState(0);
  const [paymentSilver, setPaymentSilver] = useState(0);
  const [deductSilver, setDeductSilver] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [allSkuList, setAllSkuList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [paymentDescription, setPaymentDescription] = useState("");
  const getTodaysDateInHTMLFormat = () => {
    const today = new Date();
    const year = today.getFullYear();
    // Pad the month and day with a leading zero if they are less than 10
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState(getTodaysDateInHTMLFormat());

  const [gstType, setGstType] = useState(true);
  const [advanceType, setAdvanceType] = useState("Advance Received");
  const [advanceAmount, setAdvanceAmount] = useState(0);

  const [metalPaymentOption, setMetalPaymentOption] = useState({
    optionSelected: "GOLD",
    fineRate: 0,
    fineWt: 0,
    totalAmount: 0,
    deductGold: 0,
    deductSilver: 0,
    goldRate: 0,
    silverRate: 0,
    goldAmount: 0,
    silverAmount: 0,
  });
  const [purchaseProduct, setPurchaseProduct] = useState({
    CategoryName: "",
    ProductName: "",
    BalanceGold: "0",
    BalanceSilver: "0",
    Wastage: "0",
    FineGold: "0",
    FineSilver: "0",
    id: 0,
    toAmount: false,
    CategoryId: "",
    ProductTypeId: "",
    GrossWt: "0",
    NetWt: "0",
    StoneWt: "0",
    GoldRate: "0",
    Finepercent: "0",
    making: "0",
    NetAmt: "0",
    GSTAmount: "0",
    TotalAmt: "0",
    Quantity: "1",
    PurchaseAmount: "0",
    PurchaseEntryNo: "",
    purityRate: "0",
    Purity: "0",
    finalPrice: "0",
    totalGstAmount: "0",
    purchase: true,
    unlabel: false,
    MakingFixedAmt: "0",
    MakingPercentage: "0",
    MakingPerGram: "0",
    MakingFixedWastage: "0",
    Rate: "0",
    StoneAmt: "0",
    TotalItemAmt: "0",
    FineWt: "0",
    FineWastageWt: "0",
    RDPurchaseId: "",
    tblPurity: { todaysRate: "0" },
  });
  const [unlabelProduct, setUnlabelProduct] = useState({
    CategoryName: "",
    category_id: "",
    productTypeId: "",
    product_Name: "",
    collectionName: "",
    id: 0,
    selectedUnlabelId: 0,
    grosswt: "0",
    netwt: "0",
    stonewt: "0",
    GoldRate: "0",
    Finepercent: "0",
    making: "0",
    making_Fixed_Amt: "0",
    making_Percentage: "0",
    making_per_gram: "0",
    making_Fixed_Wastage: "0",
    NetAmt: "0",
    MaxQuantity: "0",
    MaxGrossWt: "0",
    MaxNetWt: "0",
    GSTAmount: "0",
    TotalAmt: "0",
    Quantity: "1",
    unlabelAmount: "0",
    purityRate: "0",
    purity: "100%",
    finalPrice: "0",
    totalGstAmount: "0",
    purchase: false,
    unlabel: true,
    tblPurity: { todaysRate: "0" },
  });
  const [purchaseProductList, setPurchaseProductList] = useState([]);
  const [unlabelProductList, setUnlabelProductList] = useState([]);
  const [newCustomerFields, setNewCustomerFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    currAddStreet: "",
    currAddTown: "",
    currAddState: "",
    currAddPinCode: "",
    perAddStreet: "",
    perAddTown: "",
    perAddState: "",
    perAddPinCode: "",
    aadharNo: "",
    panNo: "",
    gstNo: "",
  });
  // console.log(allSelectedProducts, "allSelectedProduct");
  //   useEffect(() => {
  //     fetch(a1)
  //       .then((res) => res.json())
  //       .then((response) => {
  //         setAllCsData(response.data);
  //       });
  //   }, []);
  const fetchAllSalesTeam = async () => {
    try {
      const response = await fetch(a59);
      const data = await response.json();
      setAllSalesTeam(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllSalesTeam();
  }, []);
  const fetchAllCustomers = async () => {
    try {
      const response = await fetch(a28);
      const data = await response.json();
      setAllCsData(data.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);
  const fetchAllProducts = async () => {
    try {
      const response = await fetch(a41);
      const data = await response.json();
      setAllProducts(data.data);
      console.log(data, "Rcvd data");
      setProductsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllUnlabelledList = async () => {
    try {
      const response = await fetch(a61);
      // const response = await fetch(a56);
      const data = await response.json();
      setAllUnlabelList(data.data);
      // console.log(data, "Rcvd Unlabel data");
      setProductsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllSkuList = async () => {
    try {
      const response = await fetch(a71);
      // const response = await fetch(a56);
      const data = await response.json();
      setAllSkuList(data);
      // console.log(data, "Rcvd Unlabel data");
      setProductsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedSku, setSelectedSku] = useState([]);
  const [selectedSkuName, setSelectedSkuName] = useState("");
  const handleSkuInputChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSelectedSkuName(value);
    let selectedSkuItem = [];
    selectedSkuItem = allSkuList.find((x) => x.stockKeepingUnit == value);
    // if (selectedSkuItem) {
    setSelectedSku(selectedSkuItem);
    console.log(selectedSkuItem, "selectedSkuItem");
    console.log(selectedSkuItem, "selectedSkuItem");
    console.log(selectedSkuItem, "selectedSkuItem");
    // setSelectedCategory(selectedSkuItem.category);
    // setSelectedProductType(selectedSkuItem.productType);
    // }
  };
  // useEffect(() => {
  //   fetchAllProducts();
  // }, []);
  useEffect(() => {
    fetchAllCategories();
  }, []);
  useEffect(() => {
    fetchAllProductType();
  }, []);
  useEffect(() => {
    fetchAllPurities();
  }, []);
  useEffect(() => {
    fetchAllUnlabelledList();
  }, []);
  useEffect(() => {
    fetchAllSkuList();
  }, []);

  const navigate = useNavigate();
  console.log(selectedSku, "selectedSku");
  console.log(selectedSku, "selectedSku");
  useEffect(() => {
    if (selectedSku) {
      // setDescription(selectedSku.description);
      // setNetWt(selectedSku.netWt);
      // // categoryName = selectedSku.category;
      // // productTypeName = selectedSku.productType;
      // // collectionName = selectedSku.collection;
      // // purityName = selectedSku.purity;
      // setSelectedCategory(`${selectedSku.categoryId},${selectedSku.category}`);
      // setProductType(`${selectedSku.productTypeId},${selectedSku.productType}`);
      // setCollection(`${selectedSku.collectionId},${selectedSku.collection}`);
      // setPurity(`${selectedSku.purityId},${selectedSku.purity}`);
      // // categoryId = selectedSku.categoryId;
      // // productTypeId = selectedSku.productTypeId;
      // // purityId = selectedSku.purityId;
      // // collectionId = selectedSku.collectionId;
      // setSize(selectedSku.size);
      // setGrosswt(selectedSku.grossWt);
      // setNetWt(selectedSku.netWt);
      // setStoneWeight(selectedSku.totalStoneWt);
      // setSelectedFiles(selectedSku.images);
      // setSelectedFiles(selectedSku.images);
      // setMaking_Percentage(selectedSku.makingPercentage);
      // setMaking_Fixed_Amt(selectedSku.makingFixedAmt);
      // setMaking_per_gram(selectedSku.makingPerGram);
      // setMaking_Fixed_Wastage(selectedSku.makingFixedWastage);
      // setMRP(selectedSku.mrp);
      setSelectedCategory(`${selectedSku.categoryId},${selectedSku.category}`);
      setSelectedProductType(
        `${selectedSku.productTypeId},${selectedSku.productType}`
      );
      console.log(selectedSku.category);
      console.log(selectedSku.category);
      console.log(selectedSku.category);
      console.log(selectedSku.category);
      console.log(selectedSku.category);

      setPurchaseProduct({
        ...purchaseProduct,
        CategoryName: selectedSku.category,
        names: `${selectedSku.categoryId},${selectedSku.category}`,
        ProductName: selectedSku.productType,
        ProductNames: `${selectedSku.productTypeId},${selectedSku.productType}`,
        CategoryId: selectedSku.categoryId,
        ProductTypeId: selectedSku.productTypeId,
        GrossWt: selectedSku.grossWt,
        NetWt: selectedSku.netWt,
        StoneWt: selectedSku.totalStoneWt,
        // Purity: selectedSku.purity,
        MakingFixedAmt: selectedSku.makingFixedAmt,
        MakingPercentage: selectedSku.makingPercentage,
        MakingPerGram: selectedSku.makingPerGram,
        MakingFixedWastage: selectedSku.makingFixedWastage,
        StoneAmt:
          parseFloat(selectedSku.stoneAmount1) +
          parseFloat(selectedSku.stoneAmount3) +
          parseFloat(selectedSku.stoneAmount3) +
          parseFloat(selectedSku.stoneAmount4),
      });
    } else {
      setPurchaseProduct({
        CategoryName: "",
        ProductName: "",
        BalanceGold: "0",
        BalanceSilver: "0",
        Wastage: "0",
        FineGold: "0",
        FineSilver: "0",
        id: 0,
        // names: null,
        // ProductNames: "",
        toAmount: false,
        CategoryId: "",
        ProductTypeId: "",
        GrossWt: "0",
        NetWt: "0",
        StoneWt: "0",
        GoldRate: "0",
        Finepercent: "0",
        making: "0",
        NetAmt: "0",
        GSTAmount: "0",
        TotalAmt: "0",
        Quantity: "1",
        PurchaseAmount: "0",
        PurchaseEntryNo: "",
        purityRate: "0",
        Purity: "0",
        finalPrice: "0",
        totalGstAmount: "0",
        purchase: true,
        unlabel: false,
        MakingFixedAmt: "0",
        MakingPercentage: "0",
        MakingPerGram: "0",
        MakingFixedWastage: "0",
        Rate: "0",
        StoneAmt: "0",
        TotalItemAmt: "0",
        FineWt: "0",
        FineWastageWt: "0",
        RDPurchaseId: "",
        tblPurity: { todaysRate: "0" },
      });
      setSelectedSku([]);
      setSelectedSkuName("");
      setSelectedCategory("");
      setSelectedProductType("");
      // setPurchaseProduct({
      //   ...purchaseProduct,
      //   names: null,
      //   ProductNames: null,
      //   CategoryName: "",
      //   ProductName: "",
      //   CategoryId: "",
      //   ProductTypeId: "",
      //   GrossWt: "0",
      //   NetWt: "0",
      //   StoneWt: "0",
      //   Quantity: "1",
      //   MakingFixedAmt: "0",
      //   MakingPercentage: "0",
      //   MakingPerGram: "0",
      //   MakingFixedWastage: "0",
      //   StoneAmt: "0",
      // });
      // setDeleteAll(true);
      // setPartyTypeId("");
      // setCategory("");
      // setProductType("");
      // setPurity("");
      // setQuantity(1);
      // setCollection("");
      // setGrosswt(0);
      // setNetWt(0);
      // setGender("");
      // setStoneWeight(0);
      // setMRP(0);
      // setProductName("");
      // setDescription("");
    }
  }, [selectedSku]);
  const fetchAllCategories = async () => {
    try {
      const response = await fetch(a18);
      const data = await response.json();
      setAllCategories(data.data);
      // console.log(data, "allcategory");
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllProductType = async () => {
    try {
      const response = await fetch(a20);
      const data = await response.json();
      setAllProductTypes(data.data);
      // console.log(data, "allProductTypes");
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllPurities = async () => {
    try {
      const response = await fetch(a22);
      const data = await response.json();
      setAllPurities(data.data);
      // console.log(data, "allPurities");
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   fetchAllProducts();
  // }, []);
  // console.log(allCsData, "allCsData");
  useEffect(() => {
    if (selectedCustomer) {
      setCustomerName(selectedCustomer.firm_name);
      setCustomerMobile(selectedCustomer.contact_no);
      setCustomerId(selectedCustomer.id);
      setCustomerEmail(selectedCustomer.email_id);
      setCustomerAddress(selectedCustomer.currAddStreet);
      // handleToggleCustomTab();
    } else {
      setCustomerName("");
      setCustomerMobile("");
      setCustomerEmail("");
      setCustomerId("");
      setCustomerAddress("");
    }
  }, [selectedCustomer]);

  const filteredCustomers = allCsData.filter((customer) => {
    const fullName = `${customer.firstName} ${customer.lastName}`;
    return fullName.toLowerCase().includes(customerName.toLowerCase());
  });

  const handleNameInputChange = (e) => {
    const { value } = e.target;
    setCustomerName(value); // Update the name input value

    const selected = allCsData.find((customer) => {
      const fullName = customer.firm_name;
      return fullName.toLowerCase() === value.toLowerCase();
    });

    if (selected) {
      setCustomerEmail(selected.email);
      setCustomerId(selected.id); // Update the email input value based on selected customer's email
    }
    setSelectedCustomerEdit(false);
    setSelectedCustomer(selected); // Update the selected customer based on name match
  };

  const handleMobileInputChange = (e) => {
    const { value } = e.target;
    setCustomerMobile(value); // Update the mobile input value

    const selected = allCsData.find((customer) => customer.mobile === value);

    if (selected) {
      setCustomerEmail(selected.email);
      setCustomerId(selected.id); // Update the email input value based on selected customer's email
    }

    setSelectedCustomerEdit(false);
    setSelectedCustomer(selected); // Update the selected customer based on mobile match
  };

  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerEmail(value); // Update the mobile input value

    const selected = allCsData.find((customer) => customer.email == value);
    setSelectedCustomerEdit(false);
    setSelectedCustomer(selected); // Update the selected customer based on mobile match
  };
  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerAddress(value); // Update the mobile input value

    const selected = allCsData.find(
      (customer) => customer.currAddStreet == value
    );
    setSelectedCustomerEdit(false);
    setSelectedCustomer(selected); // Update the selected customer based on mobile match
  };

  useEffect(() => {
    if (selectedProduct) {
      setCategoryName(selectedProduct.category_Name);
      setProductName(selectedProduct.itemType);
      setCollectionName(selectedProduct.collection);
      setPurityType(selectedProduct.purity);
      setBarcode(selectedProduct.barcodeNumber);
      setLabelName(selectedProduct.itemCode);
    } else {
      setCustomerName("");
      setCustomerMobile("");
      setCustomerEmail("");
    }
  }, [selectedProduct]);

  const handleProductLabelChange = (e) => {
    const { value } = e.target;
    setLabelName(value.toUpperCase());
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setBarcode("");
    setSelectedProductPrice(0);
    if (value) {
      const selected = allProducts.find(
        (product) =>
          product.itemCode === value || product.barcodeNumber === value
      );
      if (selected) {
        setSelectedProduct(selected);
        calculateFinalPrice(selected, false);

        // addProductToList(selected);
        // setSelectedProduct([]);
        // if (labelName) {
        // } else {
        //   alert("Label is missing");
        // } // Calculate the final price
      } else {
        // console.log("Not selected");
      }
    }
  };
  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };
  const handleProductBarcodeChange = (e) => {
    const { value } = e.target;
    setBarcode(value.toUpperCase());
    setLabelName("");
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setSelectedProductPrice(0);
    if (value) {
      const selected = allProducts.find(
        (product) => product.barcodeNumber === value
      );
      if (selected) {
        setSelectedProduct(selected);
        calculateFinalPrice(selected); // Calculate the final price
      } else {
        // console.log("Not selected");
      }
    }
  };

  const calculateFinalPrice = (selectedProduct, adding) => {
    let netGoldRate =
      (parseFloat(selectedProduct.netWt) *
        parseFloat(selectedProduct.tblPurity.todaysRate)) /
      10;
    let makingCharges1 =
      parseFloat(selectedProduct.netWt) *
      parseFloat(selectedProduct.making_per_gram);
    let makingCharges2 =
      (parseFloat(netGoldRate) *
        parseFloat(selectedProduct.making_Percentage)) /
      100;
    let makingCharges3 = parseFloat(selectedProduct.making_Fixed_Amt);
    let makingCharges4 =
      (parseFloat(selectedProduct.tblPurity.todaysRate) *
        parseFloat(selectedProduct.making_Fixed_Wastage)) /
      10;
    let hallmark_amt = parseFloat(selectedProduct.hallmark_amt);

    let GST = 0.03;

    let grossTotalRate =
      parseFloat(netGoldRate) +
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4) +
      parseFloat(hallmark_amt) +
      parseFloat(selectedProduct.stoneAmount);
    let GSTAdded = parseFloat(GST) * parseFloat(grossTotalRate);
    let finalPrice = parseFloat(grossTotalRate) + parseFloat(GSTAdded);
    if (selectedProduct.mrp !== "" && selectedProduct.mrp !== 0) {
      GSTAdded = GST * parseFloat(selectedProduct.mrp);
      finalPrice = parseFloat(selectedProduct.mrp) + parseFloat(GSTAdded);
    }

    // Calculate total making charges
    let totalMakingCharges =
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4);

    let updatedProduct = {};
    // Update selectedProduct with additional properties and calculated price
    if (selectedProduct.mrp !== "" && selectedProduct.mrp !== 0) {
      updatedProduct = {
        ...selectedProduct,
        purchase: false,
        unlabel: false,
        //   finalPrice: parseFloat(finalPrice).toFixed(3),
        making: 0,
        totalGstAmount: gstType
          ? parseFloat(selectedProduct.mrp).toFixed(3) * GST
          : 0,
        finalPrice:
          parseFloat(selectedProduct.mrp) - gstType
            ? parseFloat(selectedProduct.mrp).toFixed(3) * GST
            : 0,
        // making: totalMakingCharges,
        // totalGstAmount: GSTAdded,
      };
      setSelectedProductPrice(parseFloat(selectedProduct.mrp).toFixed(3));
    } else {
      updatedProduct = {
        ...selectedProduct,
        purchase: false,
        unlabel: false,
        finalPrice: parseFloat(grossTotalRate).toFixed(3),
        //   finalPrice: parseFloat(finalPrice).toFixed(3),
        making: totalMakingCharges,
        totalGstAmount: GSTAdded,
      };
      setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
    }
    setSelectedProduct(updatedProduct); // Update the selected product
    if (adding) {
      // alert("");
      addProductToList(updatedProduct);
    } else if ((selectedProduct.length > 0, !adding)) {
      // alert("No Product Matched");
      // console.log("no product found");
      // alert("not");
    } else {
      null;
    }
    setDiscountAmount(0);
    // setAllSelectedProducts((prev) => [...prev, updatedProduct]);
    // Update the price input field
  };

  const addProductToList = (selectedProduct) => {
    if (!allSelectedProducts.some((x) => x.id === selectedProduct.id)) {
      setAllSelectedProducts((prevItems) => [...prevItems, selectedProduct]);
      setLabelName("");
      setSelectedProduct([]);
      setCategoryName("");
      setProductName("");
      setCollectionName("");
      setPurityType("");
      setProductQty("");
      setSelectedProductPrice(0);
      // scrollToCenter("adminInvoiceSelectLabelBox");
      scrollToCenter("adminInvoiceAddProductsOptionsInnerBox");
    } else {
      // alert("Product Already added");
      setSelectedProduct([]);
    }
  };

  const removeProductFromList = (id) => {
    const updatedProductList = allSelectedProducts.filter((x) => x.id !== id);
    setAllSelectedProducts(updatedProductList);
    if (allSelectedProducts && allSelectedProducts.length == 1) {
      scrollToCenter("adminInvoiceSelectCategoryBox");
    }
  };

  useEffect(() => {
    if (selectedProduct.length > 0) {
      const finalPrice = calculateFinalPrice(
        selectedProduct.netWt,
        selectedProduct.making_per_gram,
        selectedProduct.making_Percentage,
        selectedProduct.making_Fixed_Amt,
        selectedProduct.making_Fixed_Wastage,
        selectedProduct.stoneAmount,
        selectedProduct.mrp,
        selectedProduct.tblPurity.todaysRate,
        selectedProduct.id
      );

      setSelectedProductPrice(finalPrice); // Set the calculated final price here
      setTotalPrice((x) => parseFloat(x) + finalPrice);
    }
  }, [selectedProduct, gstType]);

  const calculateNetAmount = () => {
    if (allSelectedProducts.length > 0) {
      let totalNetAmount = allSelectedProducts.reduce(
        (total, product) => total + parseFloat(product.finalPrice),
        0
      );
      let totalGstAmount = gstType
        ? allSelectedProducts.reduce(
            (total, product) => total + parseFloat(product.totalGstAmount),
            0
          )
        : 0;
      let totalAmountPaying = allSelectedProducts.reduce(
        (total, product) =>
          total +
          parseFloat(product.finalPrice) +
          (gstType ? parseFloat(product.totalGstAmount) : 0),
        0
      );

      let totalGold = allSelectedProducts.reduce(
        (total, product) => total + parseFloat(product.BalanceGold),
        0
      );

      let totalSilver = allSelectedProducts.reduce(
        (total, product) => total + parseFloat(product.BalanceSilver),
        0
      );

      setTotalPayableGold(parseFloat(totalGold).toFixed(3));
      setTotalPayableSilver(parseFloat(totalSilver).toFixed(3));

      setAllProdctsNetAmount(parseFloat(totalNetAmount).toFixed(3));
      setAllProdctsGstAmount(
        gstType ? parseFloat(totalGstAmount).toFixed(3) : 0
      );
      setTotalPayableGstAmount(
        gstType ? parseFloat(totalGstAmount).toFixed(3) : 0
      );
      setTotalPayableAmount(parseFloat(totalAmountPaying).toFixed(3));
      setGrandTotal(Math.ceil(parseFloat(totalAmountPaying)).toFixed(3));
      setPaymentAmount(Math.ceil(parseFloat(totalAmountPaying)).toFixed(3));
    } else {
      setAllProdctsNetAmount(0); // Reset the total to 0 when there are no selected products
      setAllProdctsGstAmount(0); // Reset the total to 0 when there are no selected products
      setTotalPayableGstAmount(0);
      setTotalPayableAmount(0);
      setGrandTotal(0);
      setDiscountAmount(0);
      setPaymentAmount(0);
      setOldGoldAmount(0);
      setTotalPayableGold(0);
      setTotalPayableSilver(0);
    }
  };
  useEffect(() => {
    calculateNetAmount();
  }, [selectedProduct, allSelectedProducts, gstType]);

  const handleProductDiscount = (e) => {
    const { value } = e.target;
    setDiscountAmount(value);
    setAllProdctsNetAmount((x) => x - value);
  };
  const recalculatePrices = () => {
    // Calculate individual product prices and update their properties
    const updatedProducts = allSelectedProducts.map((product) => {
      // Recalculate the price based on updated properties (e.g., grosswt, stoneWeight, etc.)
      const recalculatedProduct = calculateFinalPrice(product);

      return recalculatedProduct;
    });

    // Calculate the total net amount and total GST amount
    // setAllSelectedProducts(updatedProducts);
    // calculateNetAmount();

    // Update the state with the recalculated product list
    // setAllSelectedProducts(updatedProducts);
  };
  console.log(deductGold, "deductGold");
  console.log(deductGold, "deductGold");
  const changeTotalPrice = (e) => {
    const newTotalPayableAmount = parseInt(e.target.value);
    // console.log("TotalPayAmt", totalPayableAmount);
    // console.log("NewTotalPayAmt", newTotalPayableAmount);
    const perTotalPayableAmount = newTotalPayableAmount / 103;

    setTotalPayableGstAmount((perTotalPayableAmount * 3).toFixed(3));
    setTotalPayableAmount(e.target.value);
    // setOldGoldAmount(0);
    setPaymentAmount(e.target.value);
    setPayments([]);
    setAllProdctsNetAmount((parseInt(e.target.value) * 100) / 103);

    let totalAmountPaying = allSelectedProducts.reduce(
      (total, product) =>
        total +
        parseFloat(product.finalPrice) +
        parseFloat(product.totalGstAmount),
      0
    );
    setDiscountAmount(parseInt(totalAmountPaying) - parseInt(e.target.value));
    setGrandTotal(e.target.value);
    setDeductGold(0);
    setDeductSilver(0);
    calculateNetAmount();
    // setDiscountAmount(
    //   (
    //     parseInt(allProdctsNetAmount) +
    //     parseInt(perTotalPayableAmount * 3) -
    //     parseInt(newTotalPayableAmount)
    //   ).toFixed(3)
    // );

    // setTotalPayableAmount(parseFloat(e.target.value));
    // setTotalPayableGstAmount(
    //   parseFloat(newTotalPayableAmount) +
    //     parseFloat(discountAmount) -
    //     parseFloat(allProdctsNetAmount)
    // );
  };
  const changeGrandTotal = (e) => {
    const { value } = parseInt(e.target);
    setOldGoldAmount(parseInt(value));
    // console.log("TotalPayAmt", totalPayableAmount);
    // console.log("NewTotalPayAmt", newTotalPayableAmount);
    setGrandTotal(
      parseInt(parseInt(totalPayableAmount) - parseInt(oldGoldAmount))
    );
    // setTotalPayableAmount(parseFloat(e.target.value));
    // setTotalPayableGstAmount(
    //   parseFloat(newTotalPayableAmount) +
    //     parseFloat(discountAmount) -
    //     parseFloat(allProdctsNetAmount)
    // );
  };
  const handleInputChange = (e, productId, property) => {
    const barcodeInput = document.getElementById("barcodeNumberInput");
    // barcodeInput.style.setProperty("color", "black");
    const { value } = e.target;
    // setBarCodeAlert(false);
    const updatedProducts = allSelectedProducts.map((product) => {
      if (product.id === productId) {
        // Parse properties to numbers or set them as 0 if the value is empty or invalid
        const grosswt = parseFloat(product.grosswt) || 0;
        const stoneWeight = parseFloat(product.stoneWeight) || 0;
        const netWt = parseFloat(product.netWt) || 0;

        // Update the specific property in the product object
        let updatedProduct = { ...product, [property]: value };

        if (property === "barcodeNumber") {
          // Convert the barcode number to uppercase before doing the comparison
          const barcodeValue = value.toUpperCase();
          updatedProduct.barcodeNumber = barcodeValue; // Set the barcodeNumber property to uppercase

          // Find a matching product in the rifdData array
          const matchingProduct = rifdData.find(
            (item) => item.barcodeNumber === barcodeValue
          );

          if (matchingProduct) {
            updatedProduct.tid = matchingProduct.tid;
          } else {
            // If no matching product found, set 'tid' to null or some default value
            updatedProduct.tid = null; // or any default value you want
            // setBarCodeAlert(true);
          }
        }

        // If 'grosswt' is changed, calculate 'netWt'
        if (property === "grosswt" && !isNaN(value)) {
          updatedProduct.netWt = (parseFloat(value) - stoneWeight).toFixed(3);
          calculateFinalPrice(updatedProduct);
        }

        // If 'stoneWeight' is changed, calculate 'netWt'
        if (property === "stoneWeight" && !isNaN(value)) {
          updatedProduct.netWt = (grosswt - parseFloat(value)).toFixed(3);
        }

        // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
        if (property === "netWt" && !isNaN(value)) {
          let totalMakingCharges =
            parseFloat(updatedProduct.making_per_gram) +
            parseFloat(updatedProduct.making_Percentage) +
            parseFloat(updatedProduct.making_Fixed_Amt) +
            parseFloat(updatedProduct.making_Fixed_Wastage);
          updatedProduct.grosswt = (parseFloat(value) + stoneWeight).toFixed(3);
          updatedProduct.stoneWeight = (grosswt - parseFloat(value)).toFixed(3);
        }
        if (property === "mrp" && !isNaN(value)) {
          let totalMakingCharges =
            parseFloat(updatedProduct.making_per_gram) +
            parseFloat(updatedProduct.making_Percentage) +
            parseFloat(updatedProduct.making_Fixed_Amt) +
            parseFloat(updatedProduct.making_Fixed_Wastage);
          let GST = 0.03;
          //   updatedProduct.finalPrice = parseFloat(value).toFixed(3);
          (updatedProduct.finalPrice =
            parseFloat(updatedProduct.mrp) -
            parseFloat(updatedProduct.mrp).toFixed(3) * GST),
            (updatedProduct.making = 0);
          //   updatedProduct.totalGstAmount = 0;
          updatedProduct.totalGstAmount =
            parseFloat(updatedProduct.mrp).toFixed(3) * GST;
          //   updatedProduct.making = parseFloat(totalMakingCharges).toFixed(3);
          //   console.log(totalMakingCharges);
          //   updatedProduct.totalGstAmount = parseFloat(
          //     ((parseFloat(updatedProduct.mrp) + parseFloat(totalMakingCharges)) *
          //       GST) /
          //       100
          //   );
        }
        if (
          property === "netWt" ||
          property === "grossWt" ||
          property === "stoneWt" ||
          property === "making_per_gram" ||
          property === "making_Percentage" ||
          property === "making_Fixed_Amt" ||
          property === "making_Fixed_Wastage"
        ) {
          let netGoldRate =
            (parseFloat(updatedProduct.netWt) *
              parseFloat(updatedProduct.tblPurity.todaysRate)) /
            10;
          let makingCharges1 =
            parseFloat(updatedProduct.netWt) *
            parseFloat(updatedProduct.making_per_gram);
          let makingCharges2 =
            (parseFloat(netGoldRate) *
              parseFloat(updatedProduct.making_Percentage)) /
            100;
          let makingCharges3 = parseFloat(updatedProduct.making_Fixed_Amt);
          let hallmark_amt = parseFloat(updatedProduct.hallmark_amt);

          let makingCharges4 =
            (parseFloat(updatedProduct.tblPurity.todaysRate) *
              parseFloat(updatedProduct.making_Fixed_Wastage)) /
            10;
          let GST = 0.03;

          let grossTotalRate =
            parseFloat(netGoldRate) +
            parseFloat(makingCharges1) +
            parseFloat(makingCharges2) +
            parseFloat(makingCharges3) +
            parseFloat(makingCharges4) +
            parseFloat(updatedProduct.stoneAmount);
          let GSTAdded = parseFloat(GST) * parseFloat(grossTotalRate);
          let finalPrice = parseFloat(grossTotalRate) + parseFloat(GSTAdded);

          // Calculate total making charges
          let totalMakingCharges =
            parseFloat(makingCharges1) +
            parseFloat(makingCharges2) +
            parseFloat(makingCharges3) +
            parseFloat(makingCharges4);

          // console.log(netGoldRate, "netGoldRate");
          if (updatedProduct.mrp == 0 || updatedProduct.mrp == "") {
            updatedProduct.finalPrice = parseFloat(grossTotalRate).toFixed(3);
            updatedProduct.making = totalMakingCharges;
            updatedProduct.totalGstAmount = GSTAdded;
          }
          // Update selectedProduct with additional properties and calculated price

          setSelectedProduct(updatedProduct); // Update the selected product
          setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
        }
        return updatedProduct;
      }
      return product;
    });
    setAllSelectedProducts(updatedProducts);
  };
  //   useEffect(() => {
  //     recalculatePrices();
  //   }, [allSelectedProducts]);
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  const checkPurchaseItems = () => {
    if (purchaseProductList.length > 0) {
      // console.log("Items Available");
      addPurchaseOrderItems();
      // createOrder();
    } else if (unlabelProductList.length > 0) {
      updateUnlabelOrderItems();
    } else {
      createOrder();
      // alert("Going for order");
    }
  };

  // CONTINUE FROM BELOW
  const addPurchaseOrderItems = async () => {
    try {
      const orderItemsList = purchaseProductList.map((product) => {
        return {
          CategoryName: `${product.CategoryName}`,
          productname: `${product.productname}`,
          grosswt: `${product.grosswt}`,
          netwt: `${product.netwt}`,
          stonewt: `${product.stonewt}`,
          GoldRate: `${product.GoldRate}`,
          Finepercent: `${product.Finepercent}`,
          NetAmt: `${product.NetAmt}`,
          GSTAmount: `${product.GSTAmount}`,
          TotalAmt: `${product.TotalAmt}`,
          Quantity: `${product.Quantity}`,
          PurchaseAmount: `${product.PurchaseAmount}`,
          // making_fixed_amt: `${product.making_Fixed_Amt}`,
          // making_fixed_wastage: `${product.making_Fixed_Wastage}`,
          // making_per_gram: `${product.making_per_gram}`,
          // making_percentage: `${product.making_Percentage}`,
          // hallmark_amt: "",
          // hallmark_no: "",
        };
      });
      // console.log(orderItemsList, "orderItemsList");
      const response = await fetch(a53, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderItemsList),
      });
      // console.log(orderItemsList, "orderItemsList");

      const rcvdData = await response.json();
      const purchaseProductsData = rcvdData.data;
      // console.log(purchaseProductsData, "purchaseProductsData");
      // Set the state with order items
      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        purchaseProductList.forEach((product, index) => {
          product.id = purchaseProductsData[index].id;
          product.purchase_invoice_no =
            purchaseProductsData[index].purchase_invoice_no;
        });

        if (unlabelProductList.length > 0) {
          updateUnlabelOrderItems();
        } else {
          createOrder();
          // alert("going for order");
        }
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };
  const updateUnlabelOrderItems = async () => {
    try {
      // Map through unlabelProductList and update the corresponding item in allUnlabelList
      const updatedUnlabelItems = unlabelProductList.map((product) => {
        // Find the corresponding item in allUnlabelList based on id
        const filteredUnlabelItem = allUnlabelList.find(
          (x) => x.id === product.id
        );

        if (filteredUnlabelItem) {
          // Subtract quantities and other properties from filteredUnlabelItem
          filteredUnlabelItem.grosswt = (
            parseFloat(filteredUnlabelItem.grosswt) -
            parseFloat(product.grosswt)
          ).toFixed(3);
          filteredUnlabelItem.stoneWeight = (
            parseFloat(filteredUnlabelItem.stoneWeight) -
            parseFloat(product.stonewt)
          ).toFixed(3);
          filteredUnlabelItem.netWt = (
            parseFloat(filteredUnlabelItem.netWt) - parseFloat(product.netwt)
          ).toFixed(3);
          filteredUnlabelItem.quantity = (
            parseInt(filteredUnlabelItem.quantity) - parseInt(product.Quantity)
          ).toString();
        }

        return filteredUnlabelItem; // Updated unlabel item
      });

      // Filter out null or undefined items (in case any item was not found in allUnlabelList)
      const validUnlabelItems = updatedUnlabelItems.filter((item) => item);
      // console.log(validUnlabelItems[0], "validUnlabelItems[0]");
      // Send the updated unlabel item to the API
      const response = await fetch(a57, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(validUnlabelItems[0]), // Assuming you're updating only one item
      });

      const rcvdData = await response.json();
      // console.log(rcvdData.data, "Unlabel hit");
      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        createOrder();
        // alert("Going for order")
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  const createOrder2 = () => {
    let totalGold = allSelectedProducts.reduce(
      (total, product) => total + parseFloat(product.BalanceGold),
      0
    );

    let totalSilver = allSelectedProducts.reduce(
      (total, product) => total + parseFloat(product.BalanceSilver),
      0
    );
    const formData = {
      NetAmount: `${parseFloat(allProdctsNetAmount).toFixed(3)}`,
      GSTAmount: `${parseFloat(totalPayableGstAmount).toFixed(3)}`,
      TotalAmount: `${Math.ceil(totalPayableAmount).toFixed(3)}`,
      Quantity: `${allSelectedProducts.length}`,
      PurchaseAmount: `${Math.ceil(totalPayableAmount).toFixed(3)}`,
      SupplierId: selectedCustomer.id,
      Branch: "Home",
      PurchaseType: "purchase",
      Discount: `${parseFloat(discountAmount).toFixed(3)}`,
      Remark: "",
      BalanceGold: `${parseFloat(totalPayableGold).toFixed(3)}`,
      BalanceSilver: `${parseFloat(totalPayableSilver).toFixed(3)}`,
      BalanceAmount: `${parseFloat(grandTotal).toFixed(3)}`,
      FineGold: `${parseFloat(totalGold).toFixed(3)}`,
      FineSilver: `${parseFloat(totalSilver).toFixed(3)}`,
    };
    console.log(formData, "formData for order Main");
  };
  const createOrder = async () => {
    let totalGold = allSelectedProducts.reduce(
      (total, product) => total + parseFloat(product.BalanceGold),
      0
    );

    let totalSilver = allSelectedProducts.reduce(
      (total, product) => total + parseFloat(product.BalanceSilver),
      0
    );

    // Determine the date to send
    const dateToSend = selectedDate || getTodaysDateInHTMLFormat();

    try {
      const formData = new FormData();

      formData.append("NetAmount", parseFloat(allProdctsNetAmount).toFixed(3));
      formData.append(
        "GSTAmount",
        parseFloat(totalPayableGstAmount).toFixed(3)
      );
      formData.append("TotalAmount", Math.ceil(totalPayableAmount).toFixed(3));
      formData.append("Quantity", allSelectedProducts.length);
      formData.append(
        "PurchaseAmount",
        Math.ceil(totalPayableAmount).toFixed(3)
      );
      formData.append("SupplierId", selectedCustomer.id);
      formData.append("Branch", "Home");
      formData.append("PurchaseType", "purchase");
      formData.append("Discount", parseFloat(discountAmount).toFixed(3));
      formData.append("Remark", "");
      formData.append("BalanceGold", parseFloat(totalPayableGold).toFixed(3));
      formData.append(
        "BalanceSilver",
        parseFloat(totalPayableSilver).toFixed(3)
      );
      formData.append("BalanceAmount", parseFloat(grandTotal).toFixed(3));
      formData.append("DebitAmount", "0");
      formData.append("DebitGold", "0");
      formData.append("DebitSilver", "0");
      formData.append("FineGold", parseFloat(totalGold).toFixed(3));
      formData.append("FineSilver", parseFloat(totalSilver).toFixed(3));
      formData.append("InvoiceNo", invoiceNumber);
      // formData.append("InvoiceFile", "");
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file) => {
          formData.append("InvoiceFile", file);
        });
        console.log("Images Selected");
      } else {
        formData.append("Images", "");
        console.log(" No Images Selected");
      }
      formData.append("InwardNo", `${selectedCustomer.inwardNo}`);
      formData.append("PurchaseDate", `${dateToSend}`);

      // console.log(formData, "FORMDATA FOR ORDER")
      // const formData = {
      //   NetAmount: `${parseFloat(allProdctsNetAmount).toFixed(3)}`,
      //   GSTAmount: `${parseFloat(totalPayableGstAmount).toFixed(3)}`,
      //   TotalAmount: `${Math.ceil(totalPayableAmount).toFixed(3)}`,
      //   Quantity: allSelectedProducts.length,
      //   PurchaseAmount: `${Math.ceil(totalPayableAmount).toFixed(3)}`,
      //   SupplierId: selectedCustomer.id,
      //   Branch: "Home",
      //   PurchaseType: "purchase",
      //   Discount: `${parseFloat(discountAmount).toFixed(3)}`,
      //   Remark: "",
      //   BalanceGold: `${parseFloat(totalPayableGold).toFixed(3)}`,
      //   BalanceSilver: `${parseFloat(totalPayableSilver).toFixed(3)}`,
      //   BalanceAmount: `${parseFloat(grandTotal).toFixed(3)}`,
      //   FineGold: `${parseFloat(totalGold).toFixed(3)}`,
      //   FineSilver: `${parseFloat(totalSilver).toFixed(3)}`,
      // };
      console.log(formData, "FORMDATA FOR ORDER");
      const response = await fetch(a65, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      });
      const rcvdData = await response.json();
      const orderData = rcvdData.data;
      // setOrderId(orderData.id);
      // setOrderCsData(rcvdData.data);
      console.log(rcvdData.data, "1st hit");
      // createOrderItems(rcvdData.data.id);
      if (rcvdData.status === "error") {
        // setLoading(false);
        alert(rcvdData.message); // Show error message
      } else {
        // setLoading(false);
        console.log(rcvdData.data, "orderCreated");
        createOrderItems(rcvdData.data.id, rcvdData.data);
      }
    } catch (error) {
      alert(error);
      console.error(error);
      //   setLoading(false);
    }
  };

  const createOrderItems = async (rcvdId, x) => {
    try {
      const orderItemsList = allSelectedProducts.map((product) => {
        let item = {
          CategoryName: product.CategoryName,
          ProductName: product.ProductName,
          GrossWt: `${product.GrossWt}`,
          NetWt: `${product.NetWt}`,
          StoneWt: `${product.StoneWt}`,
          MakingFixedAmt: `${product.MakingFixedAmt}`,
          MakingPercentage: `${product.MakingPercentage}`,
          MakingPerGram: `${product.MakingPerGram}`,
          MakingFixedWastage: `${product.MakingFixedWastage}`,
          Rate: `${product.Rate}`,
          Finepercent: `${product.Finepercent}`,
          Quantity: `${product.Quantity}`,
          Purity: product.Purity,
          CategoryId: parseInt(product.CategoryId),
          ProductTypeId: parseInt(product.ProductTypeId),
          PurchaseEntryNo: product.PurchaseEntryNo,
          FineGold: `${product.FineGold}`,
          FineSilver: `${product.FineSilver}`,
          StoneAmt: `${product.StoneAmt}`,
          TotalItemAmt: `${product.TotalItemAmt}`,
          FineWt: `${product.FineWt}`,
          FineWastageWt: `${product.FineWastageWt}`,
          RDPurchaseId: parseInt(rcvdId),
          // SupplierId: parseInt(customerId),

          // BalanceGold: `${product.BalanceGold}`,
          // BalanceSilver: `${product.BalanceSilver}`,
          // Wastage: `${product.Wastage}`,
          // GoldRate: `${product.GoldRate}`,
          // making: `${product.making}`,
          // NetAmt: `${product.NetAmt}`,
          // GSTAmount: `${product.GSTAmount}`,
          // TotalAmt: `${product.TotalAmt}`,
          // PurchaseAmount: `${product.PurchaseAmount}`,
          // purityRate: product.purityRate,
          // finalPrice: product.finalPrice,
          // totalGstAmount: product.totalGstAmount,
        };

        return item;
      });
      console.log(orderItemsList, "orderItemsList to send");
      const response = await fetch(a66, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderItemsList),
      });

      const rcvdData = await response.json();
      const orderData = rcvdData.data;
      console.log(orderData, "2nd Hit OrderItems created");

      // Set the state with order items
      setOrderItemsData(orderData);
      setOrderItems(orderData);

      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        // Generate bill PDF after setting the state
        // generateBillPDF(rcvdData.data, x);
        // resetAllFields();
        addAllSelectedPayments();
        window.scrollTo(0, 0);
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };
  console.log(payments, "payments");
  console.log(payments, "payments");
  const currentYear = new Date().getFullYear();
  console.log(currentYear, "current Year");
  const addAllSelectedPayments = async () => {
    try {
      const paymentsList = payments.map((payment) => {
        let item = {
          PaymentSource: "Purchase Bill",
          PaymentModeType: `${payment.mode}`,
          CreditDebit: "Debit",
          Description: payment.paymentDescription,
          Amount: `${payment.amount}`,
          TransactionType: `${payment.paymentType}`,
          SupplierId: parseInt(customerId),
          InvoiceNumber: `${invoiceNumber}`,
          CustomerId: 0,
          BillType: "purchase",
          PaymentVisibility: "Active",
          financialYear: `${currentYear}`,
          Branch: "Home",
          FineGold: `${payment.fineGold}`,
          FineSilver: `${payment.fineSilver}`,
          SilverRate: `${payment.silverRate}`,
          GoldRate: `${payment.goldRate}`,
          SilverAmount: `${payment.goldAmount}`,
          GoldAmount: `${payment.silverAmount}`,
          OldGoldGrosswt: `${payment.fineGold}`,
          OldSilverGrosswt: `${payment.fineSilver}`,
          GoldPurity: "",
          SilverPurity: "",
          CustomerName: "",
          SupplierName: `${selectedCustomer.supplier_name}`,
          InwardNo: `${parseInt(selectedCustomer.inwardNo) + 1}`,
        };

        return item;
      });
      console.log(paymentsList, "paymentsList to send");
      const response = await fetch(a74, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentsList),
      });

      const rcvdData = await response.json();
      const paymentsData = rcvdData.data;
      console.log(paymentsData, "3rd Hit payment modes created");

      if (rcvdData.status === "error") {
        alert(rcvdData.message);
      } else {
        // Generate bill PDF after setting the state
        // generateBillPDF(rcvdData.data, x);
        resetAllFields();
        // addAllSelectedPayments()

        window.scrollTo(0, 0);
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };
  // console.log(orderCsData, "orderCsData");
  console.log(selectedCustomer, "selectedCustomer");
  // Bill format for Sk Khandre
  const generateBillPDF = (x, csData) => {
    const doc = new jsPDF({
      orientation: "portrait",
      // format: "a5",
      format: [180, 250],
    });

    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(13);
    doc.setFont("times");
    doc.text("Tax Invoice", 77, 42);
    doc.setFontSize(10);
    doc.setFont("times");
    // doc.text("GST-No-27BBKPK5411K1ZI", 100, 35);
    let y = 50; // Adjust starting Y position
    const columnWidth = 15; // Adjust column widths for A5
    const contentWidth = 120; // Adjust content width for A5
    let srNo = 1;
    let pGSrNo = 1;
    let pSSrNo = 1;
    let totalOldGoldAmount = 0;
    let totalOldNotGoldAmount = 0;
    const purchaseItems = x.filter(
      (product) => product.billtype === "purchase"
    );
    // console.log(x, "x");
    if (csData) {
      doc.text(`Mobile - ${csData.tblCustomerDetails.mobile}`, 5, y);
      doc.text(
        `Name - ${csData.tblCustomerDetails.firstName} ${csData.tblCustomerDetails.lastName}`,
        5,
        y + 5
      );
      doc.text(
        `Address - ${csData.tblCustomerDetails.currAddStreet} ${csData.tblCustomerDetails.currAddTown} ${csData.tblCustomerDetails.currAddState} ${csData.tblCustomerDetails.currAddPinCode}`,
        5,
        y + 10
      );
      doc.text(`Invoice No - ${csData.invoiceNo}`, 125, y);
      doc.text(
        `Date - ${new Date(csData.createdOn).toLocaleDateString()}`,
        125,
        y + 5
      );
      doc.text(`Email - ${csData.tblCustomerDetails.email}`, 125, y + 10);
      doc.text(`Pan Card - ${csData.tblCustomerDetails.panNo}`, 5, y + 15);
      doc.text(`Gst No - ${csData.tblCustomerDetails.gstNo}`, 125, y + 15);
    }

    doc.line(5, y + 20, 175, y + 20);
    y = 75;
    doc.setFontSize(9);
    doc.text("No", 6, y);
    doc.text("Items", 12, y);
    doc.text("HSN", 65, y);
    doc.text("Pc/Pr", 75, y);
    doc.text("Purity", 85, y);
    doc.text("Grs.Wt", 95, y);
    doc.text("Net.Wt", 107, y);
    doc.text("Rate", 119, y);
    doc.text("Orn Amt", 130, y);
    doc.text("Labour", 145, y);
    doc.text("Price", 160, y);
    doc.line(5, y + 3, 175, y + 3);

    const maxPageHeight = doc.internal.pageSize.height - 20;
    y += 10;
    doc.setFontSize(9);
    let soldProducts = x.filter((product) => product.billtype !== "purchase");

    soldProducts.forEach((item) => {
      if (y + 8 > doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 10; // Reset Y position for the new page
      }

      doc.text(srNo.toString(), 6, y);
      const productName =
        item.productName && item.productName.length > 15
          ? item.productName.substring(0, 12) + "..."
          : item.productName;

      doc.text(productName ? productName : "-", 12, y);
      doc.setFontSize(7);
      doc.setFont("times", "bold");
      doc.text(
        item.hallmark_amt ? `Hallmark amt-${item.hallmark_amt}` : "",
        12,
        y + 3
      );
      doc.setFont("times", "normal");
      doc.setFontSize(9);
      doc.text(
        item.hsnCode && item.hsnCode !== "null" && item.hsnCode !== "undefined"
          ? item.hsnCode
          : "-",
        65,
        y
      );
      doc.setFontSize(7);
      doc.setFont("times", "bold");
      doc.text(item.huidCode ? `UID-${item.huidCode}` : "", 70, y + 3);
      doc.setFontSize(9);
      doc.setFont("times", "normal");
      doc.text(item.quantity !== "null" ? item.quantity : "-", 75, y);
      doc.text(item.purity ? item.purity : "-", 85, y);
      doc.text(item.grosswt ? item.grosswt : "-", 95, y);
      doc.text(item.netWt ? item.netWt : "-", 107, y);
      doc.text(item.rate ? item.rate : "-", 119, y);
      doc.text(
        parseFloat(
          (parseFloat(item.rate) / 10) * parseFloat(item.netWt)
        ).toFixed(2),
        130,
        y
      );
      const price =
        item.billtype !== "purchase"
          ? ((parseFloat(item.price) * 100) / 103).toFixed(2)
          : parseFloat(item.price).toFixed(2);
      const makingCharges = [
        item.making_fixed_amt,
        item.making_fixed_wastage,
        item.making_per_gram,
        item.making_percentage,
      ];

      // Filter out null, empty, or zero making charges
      const validMakingCharges = makingCharges.filter(
        (charge) => charge !== null && parseInt(charge) !== 0
      );

      // Choose making charge(s) based on the number of valid charges
      let makingChargeText = "";
      if (validMakingCharges.length > 1) {
        makingChargeText = `${parseFloat(item.makingchrg).toFixed(0)}`;
      } else if (
        item.making_percentage !== null &&
        parseInt(item.making_percentage) !== 0
      ) {
        makingChargeText = `${parseFloat(item.making_percentage).toFixed(0)}%`;
      } else if (
        item.making_per_gram !== null &&
        parseInt(item.making_per_gram) !== 0
      ) {
        makingChargeText = `${parseFloat(item.making_per_gram).toFixed(0)}/Gm`;
      } else if (validMakingCharges.length === 1) {
        makingChargeText = `${parseFloat(validMakingCharges[0]).toFixed(0)}`;
      } else {
        makingChargeText = `${parseFloat(item.makingchrg).toFixed(0)}`;
      }

      // Add making charge to PDF
      doc.text(makingChargeText, 145, y);
      // doc.text("0.00", 115, y);
      doc.text(price, 160, y);
      srNo++;
      y += 8;
    });

    doc.line(5, y - 3, 175, y - 3);
    y += 10;
    doc.setFontSize(7);
    if (purchaseItems.length > 0) {
      doc.line(10, y - 4, 75, y - 4);
      y += 0;
      doc.text("Item", 11, y);
      doc.text("Inv No", 36, y);
      doc.text("Amount", 61, y);
      doc.line(10, y - 4, 10, y + 2);
      doc.line(35, y - 4, 35, y + 2);
      doc.line(60, y - 4, 60, y + 2);
      doc.line(75, y - 4, 75, y + 2);

      y += 4;
      doc.line(10, y - 3, 75, y - 3);

      purchaseItems.forEach((product) => {
        y += 4;
        doc.line(10, y - 6, 10, y + 2);
        doc.line(35, y - 6, 35, y + 2);
        doc.line(60, y - 6, 60, y + 2);
        doc.line(75, y - 6, 75, y + 2);
        doc.text(product.productName || "N/A", 11, y);
        // doc.text(parseFloat(product.netWt).toFixed(3) || "0", 41, y);
        doc.text(product.purchase_invoice_no || "0", 36, y);
        doc.text((parseFloat(product.price) * -1).toFixed(0) || "0", 61, y);
      });

      y += 5;
      doc.line(10, y - 3, 85, y - 3);
    }

    let paymentModes = csData.paymentMode ? csData.paymentMode.split(",") : [];
    doc.setFontSize(9);
    y += 10;
    doc.text(`Payment Mode`, 10, y);
    let yPaymentModes = y + 5;
    paymentModes.forEach((paymentMode) => {
      if (yPaymentModes > maxPageHeight - 10) {
        doc.addPage();
        yPaymentModes = 5;
      }
      const [mode, amount] = paymentMode.split(":");
      doc.text(`${mode}`, 10, yPaymentModes);
      doc.text(`${amount}`, 10 + columnWidth, yPaymentModes);
      yPaymentModes += 5;
    });

    let totalSaleAmount = soldProducts.reduce((total, product) => {
      return total + parseFloat((parseFloat(product.price) * 100) / 103 || 0);
    }, 0);

    let payableGst = parseFloat(totalSaleAmount) * 0.03;

    y += 10;
    doc.text(`Sales Amount:`, 125, y);
    doc.text(`${parseFloat(totalSaleAmount).toFixed(2)}`, 155, y);
    doc.text(`CGST 1.5%:`, 125, y + 5);
    doc.text(`${parseFloat(payableGst).toFixed(2) / 2}`, 155, y + 5);
    doc.text(`SGST 1.5%:`, 125, y + 10);
    doc.text(`${parseFloat(payableGst).toFixed(2) / 2}`, 155, y + 10);
    doc.text(`R.O./Discount:`, 125, y + 15);
    doc.text(`${csData.offer}`, 155, y + 15);
    doc.text(`Purchase Amount (-):`, 125, y + 20);
    doc.text(`${parseFloat(csData.purchaseAmt).toFixed(2)}`, 155, y + 20);
    doc.text(`Recieved Amount:`, 125, y + 25);
    doc.text(`${parseFloat(csData.receivedAmt).toFixed(2)}`, 155, y + 25);
    doc.text(`Total:`, 125, y + 30);
    doc.text(`${parseFloat(csData.price).toFixed(2)}`, 155, y + 30);

    let totalAmountInWords = numberToIndianWords(
      parseFloat(csData.price).toFixed(0)
    );
    doc.text(`Total in Words: ${totalAmountInWords} Only`, 10, y + 40);

    let footerY = doc.internal.pageSize.height - 40;
    doc.setFontSize(9);
    // doc.text("Raja Bazar, P.O. Jatni-752050, Khordha (Odisha)", 10, footerY);
    // doc.text("Phone Number: 0674-2492089 ", 10, footerY + 4);
    // doc.text("Mobile / Whatsapp: +91 7978114496 ", 10, footerY + 8);
    doc.text("Customer Signature", 10, footerY);
    doc.text(`Bill By - ${csData.billedby} `, 60, footerY - 5);
    doc.text(`Salesman - ${csData.soldby} `, 60, footerY);
    doc.text("For S.K Khandre Jewellers", 135, footerY);

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  // Soni Jewellers Bill Below
  // const generateBillPDF = (x, csData) => {
  //   const doc = new jsPDF({
  //     orientation: "portrait",
  //     // format: "a5",
  //     format: [160, 235],
  //   });

  //   doc.setDrawColor(0, 0, 0);
  //   doc.setFontSize(11);
  //   doc.setFont("times");
  //   doc.text("Tax Invoice", 70, 42);
  //   doc.setFontSize(9);
  //   doc.text("GST:21AGAPS4855P1ZZ", 5, 42);
  //   doc.setFont("times");
  //   // doc.text("GST-No-27BBKPK5411K1ZI", 100, 35);
  //   let y = 50; // Adjust starting Y position
  //   const columnWidth = 15; // Adjust column widths for A5
  //   const contentWidth = 120; // Adjust content width for A5
  //   let srNo = 1;
  //   let pGSrNo = 1;
  //   let pSSrNo = 1;
  //   let totalOldGoldAmount = 0;
  //   let totalOldNotGoldAmount = 0;
  //   const bulletPoint = "\u2022";

  //   const purchaseItems = x.filter(
  //     (product) => product.billtype === "purchase"
  //   );
  //   console.log(x, "x");
  //   if (csData) {
  //     doc.text(`Mobile - ${csData.tblCustomerDetails.mobile}`, 5, y);
  //     doc.text(
  //       `Name - ${csData.tblCustomerDetails.firstName} ${csData.tblCustomerDetails.lastName}`,
  //       5,
  //       y + 5
  //     );
  //     doc.text(
  //       `Address - ${csData.tblCustomerDetails.currAddStreet} ${csData.tblCustomerDetails.currAddTown} ${csData.tblCustomerDetails.currAddState} ${csData.tblCustomerDetails.currAddPinCode}`,
  //       5,
  //       y + 10
  //     );
  //     doc.text(`Invoice No - ${csData.invoiceNo}`, 105, y);
  //     doc.text(
  //       `Date - ${new Date(csData.createdOn).toLocaleDateString()}`,
  //       105,
  //       y + 5
  //     );
  //     doc.text(`Email - ${csData.tblCustomerDetails.email}`, 105, y + 10);
  //     doc.text(`Pan Card - ${csData.tblCustomerDetails.panNo}`, 5, y + 15);
  //     doc.text(`Gst No - ${csData.tblCustomerDetails.gstNo}`, 105, y + 15);
  //   }

  //   doc.line(5, y + 20, 155, y + 20);
  //   y = 75;
  //   doc.setFontSize(8);
  //   doc.text("No", 6, y);
  //   doc.text("Items", 12, y);
  //   doc.text("HSN", 65, y);
  //   doc.text("Pc/Pr", 75, y);
  //   doc.text("Purity", 85, y);
  //   doc.text("Grs.Wt", 95, y);
  //   doc.text("Net.Wt", 107, y);
  //   doc.text("Rate", 119, y);
  //   doc.text("Other", 130, y);
  //   // doc.text("Labour", 145, y);
  //   doc.text("Price", 145, y);
  //   doc.text("(incl MC)", 145, y + 3);
  //   doc.line(5, y + 6, 155, y + 6);

  //   const maxPageHeight = doc.internal.pageSize.height - 20;
  //   y += 10;
  //   doc.setFontSize(8);
  //   let soldProducts = x.filter((product) => product.billtype !== "purchase");

  //   soldProducts.forEach((item) => {
  //     if (y + 8 > doc.internal.pageSize.height - 10) {
  //       doc.addPage();
  //       y = 10; // Reset Y position for the new page
  //     }

  //     doc.text(srNo.toString(), 6, y);
  //     const productName =
  //       item.productName && item.productName.length > 15
  //         ? item.productName.substring(0, 12) + "..."
  //         : item.productName;

  //     doc.text(productName ? productName : "-", 12, y);
  //     doc.setFontSize(6);
  //     doc.setFont("times", "bold");
  //     doc.text(
  //       item.hallmark_amt ? `Hallmark amt-${item.hallmark_amt}` : "",
  //       12,
  //       y + 3
  //     );
  //     doc.setFont("times", "normal");
  //     doc.setFontSize(8);
  //     doc.text(
  //       item.hsnCode && item.hsnCode !== "null" && item.hsnCode !== "undefined"
  //         ? item.hsnCode
  //         : "-",
  //       65,
  //       y
  //     );
  //     doc.setFontSize(6);
  //     doc.setFont("times", "bold");
  //     doc.text(item.huidCode ? `UID-${item.huidCode}` : "", 70, y + 3);
  //     doc.setFontSize(8);
  //     doc.setFont("times", "normal");
  //     doc.text(item.quantity !== "null" ? item.quantity : "-", 75, y);
  //     doc.text(item.purity ? item.purity : "-", 85, y);
  //     doc.text(item.grosswt ? item.grosswt : "-", 95, y);
  //     doc.text(item.netWt ? item.netWt : "-", 107, y);
  //     doc.text(item.rate ? item.rate : "-", 119, y);
  //     doc.text(
  //       parseFloat(
  //         (parseFloat(item.rate) / 10) * parseFloat(item.netWt)
  //       ).toFixed(2),
  //       130,
  //       y
  //     );
  //     const price =
  //       item.billtype !== "purchase"
  //         ? ((parseFloat(item.price) * 100) / 103).toFixed(2)
  //         : parseFloat(item.price).toFixed(2);
  //     const makingCharges = [
  //       item.making_fixed_amt,
  //       item.making_fixed_wastage,
  //       item.making_per_gram,
  //       item.making_percentage,
  //     ];

  //     // Filter out null, empty, or zero making charges
  //     const validMakingCharges = makingCharges.filter(
  //       (charge) => charge !== null && parseInt(charge) !== 0
  //     );

  //     // Choose making charge(s) based on the number of valid charges
  //     let makingChargeText = "";
  //     if (validMakingCharges.length > 1) {
  //       makingChargeText = `${parseFloat(item.makingchrg).toFixed(0)}`;
  //     } else if (
  //       item.making_percentage !== null &&
  //       parseInt(item.making_percentage) !== 0
  //     ) {
  //       makingChargeText = `${parseFloat(item.making_percentage).toFixed(0)}%`;
  //     } else if (
  //       item.making_per_gram !== null &&
  //       parseInt(item.making_per_gram) !== 0
  //     ) {
  //       makingChargeText = `${parseFloat(item.making_per_gram).toFixed(0)}/Gm`;
  //     } else if (validMakingCharges.length === 1) {
  //       makingChargeText = `${parseFloat(validMakingCharges[0]).toFixed(0)}`;
  //     } else {
  //       makingChargeText = `${parseFloat(item.makingchrg).toFixed(0)}`;
  //     }

  //     // Add making charge to PDF
  //     // doc.text(makingChargeText, 145, y);
  //     // doc.text("0.00", 115, y);
  //     doc.text(price, 145, y);
  //     srNo++;
  //     y += 8;
  //   });

  //   doc.line(5, y - 3, 155, y - 3);
  //   y += 10;
  //   doc.setFontSize(7);
  //   if (purchaseItems.length > 0) {
  //     doc.line(10, y - 4, 75, y - 4);
  //     y += 0;
  //     doc.text("Item", 11, y);
  //     doc.text("Inv No", 36, y);
  //     doc.text("Amount", 61, y);
  //     doc.line(10, y - 4, 10, y + 2);
  //     doc.line(35, y - 4, 35, y + 2);
  //     doc.line(60, y - 4, 60, y + 2);
  //     doc.line(75, y - 4, 75, y + 2);

  //     y += 4;
  //     doc.line(10, y - 3, 75, y - 3);

  //     purchaseItems.forEach((product) => {
  //       y += 4;
  //       doc.line(10, y - 6, 10, y + 2);
  //       doc.line(35, y - 6, 35, y + 2);
  //       doc.line(60, y - 6, 60, y + 2);
  //       doc.line(75, y - 6, 75, y + 2);
  //       doc.text(product.productName || "N/A", 11, y);
  //       // doc.text(parseFloat(product.netWt).toFixed(3) || "0", 41, y);
  //       doc.text(product.purchase_invoice_no || "0", 36, y);
  //       doc.text((parseFloat(product.price) * -1).toFixed(0) || "0", 61, y);
  //     });

  //     y += 5;
  //     doc.line(10, y - 3, 75, y - 3);
  //   }

  //   let paymentModes = csData.paymentMode ? csData.paymentMode.split(",") : [];
  //   doc.setFontSize(9);
  //   y += 10;
  //   doc.text(`Payment Mode`, 10, y);
  //   let yPaymentModes = y + 5;
  //   paymentModes.forEach((paymentMode) => {
  //     if (yPaymentModes > maxPageHeight - 10) {
  //       doc.addPage();
  //       yPaymentModes = 5;
  //     }
  //     const [mode, amount] = paymentMode.split(":");
  //     doc.text(`${mode}`, 10, yPaymentModes);
  //     doc.text(`${amount}`, 10 + columnWidth, yPaymentModes);
  //     yPaymentModes += 5;
  //   });

  //   let totalSaleAmount = soldProducts.reduce((total, product) => {
  //     return total + parseFloat((parseFloat(product.price) * 100) / 103 || 0);
  //   }, 0);

  //   let payableGst = parseFloat(totalSaleAmount) * 0.03;

  //   y += 0;
  //   doc.text(`Sales Amount:`, 110, y);
  //   doc.text(`${parseFloat(totalSaleAmount).toFixed(2)}`, 140, y);
  //   doc.text(`CGST 1.5%:`, 110, y + 5);
  //   doc.text(`${parseFloat(payableGst).toFixed(2) / 2}`, 140, y + 5);
  //   doc.text(`SGST 1.5%:`, 110, y + 10);
  //   doc.text(`${parseFloat(payableGst).toFixed(2) / 2}`, 140, y + 10);
  //   doc.text(`R.O./Discount:`, 110, y + 15);
  //   doc.text(`${csData.offer}`, 140, y + 15);
  //   doc.text(`Purchase Amount (-):`, 110, y + 20);
  //   doc.text(`${parseFloat(csData.purchaseAmt).toFixed(2)}`, 140, y + 20);
  //   doc.text(`Recieved Amount:`, 110, y + 25);
  //   doc.text(`${parseFloat(csData.receivedAmt).toFixed(2)}`, 140, y + 25);
  //   doc.text(`Total:`, 110, y + 30);
  //   doc.text(`${parseFloat(csData.price).toFixed(2)}`, 140, y + 30);

  //   let totalAmountInWords = numberToIndianWords(
  //     parseFloat(csData.price).toFixed(0)
  //   );
  //   doc.text(`Total in Words: ${totalAmountInWords} Only`, 10, y + 40);

  //   let footerY = doc.internal.pageSize.height - 10;
  //   doc.setFontSize(8);
  //   // doc.text("Raja Bazar, P.O. Jatni-752050, Khordha (Odisha)", 10, footerY);
  //   // doc.text("Phone Number: 0674-2492089 ", 10, footerY + 4);
  //   // doc.text("Mobile / Whatsapp: +91 7978114496 ", 10, footerY + 8);
  //   doc.text("TERM AND CONDITION", 10, footerY - 35);
  //   doc.text(
  //     `${bulletPoint} We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct`,
  //     10,
  //     footerY - 30
  //   );
  //   doc.text(
  //     `${bulletPoint} The registration certificate is valid on the date of issue of this invoice`,
  //     10,
  //     footerY - 26
  //   );
  //   doc.text(
  //     `${bulletPoint} Goods once sold can be exchanged only if returned unused within 7 days`,
  //     10,
  //     footerY - 22
  //   );
  //   doc.text(
  //     `${bulletPoint} Weight and pieces verified and found Ok`,
  //     10,
  //     footerY - 18
  //   );
  //   doc.text(
  //     `${bulletPoint} All disutes are Subject to Bhubaneswar jurisidiction only`,
  //     10,
  //     footerY - 14
  //   );

  //   doc.setFontSize(9);
  //   doc.text("Customer Signature", 10, footerY);
  //   doc.text(`Bill By - ${csData.billedby} `, 55, footerY - 5);
  //   doc.text(`Sold By - ${csData.soldby} `, 55, footerY);
  //   doc.text("For Soni Jewellers", 125, footerY);

  //   const pdfBlob = doc.output("blob");
  //   const pdfUrl = URL.createObjectURL(pdfBlob);
  //   window.open(pdfUrl, "_blank");
  // };
  // useEffect(() => {
  //   if (orderItems.length > 0) {
  //     generateBillPDF(orderItems);
  //   }
  // }, [orderItems]);
  function showPDFWithId(id) {
    // Make the API POST request with the ID
    fetch(a51, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob()) // Convert response to Blob
      .then((blob) => {
        // Create a URL for the Blob object
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, "_blank");
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const scrollToCenter = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const handleCustomerInputChange = (e, property) => {
    const updatedUserDetails = {
      ...selectedCustomer,
      [property]: e.target.value,
    };

    setSelectedCustomer(updatedUserDetails);
    // fetchAllCustomers();
    // console.log(selectedCustomer);
  };
  const handleNewCustomerInputChange = (e, property) => {
    const updatedUserDetails = {
      ...newCustomerFields,
      [property]: e.target.value,
    };

    setNewCustomerFields(updatedUserDetails);
    // fetchAllCustomers();
    // console.log(newCustomerFields);
  };
  const updateCustomerDetails = async () => {
    let updatedUserDetails = [];
    try {
      const response = await fetch(a64, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedCustomer),
      });
      const data = await response.json();
      // console.log(data, "selected Cs");
      //   alert("updated cs");
      setSelectedCustomer(data.data);
      fetchAllCustomers();
      setSelectedCustomerEdit(false);
      scrollToCenter("adminInvoiceAddCustomerTitle");
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  //   console.log(selectedCustomer, "selectedCustomer");
  //   console.log(customerMobile, "customerMobile");
  const checkIfNewCs = () => {
    if (customerMobile.length > 7 && !selectedCustomer) {
      addNewCustomerData();
    } else {
      alert(
        "Either Customer mobile should be greater than 8 char / customer already exists "
      );
    }
  };
  const addNewCustomerData = async () => {
    // e.preventDefault();
    const formData = {
      firstName: customerName,
      lastName: "",
      email: customerEmail ? customerEmail : `${customerMobile}@example.com`,
      password: customerMobile,
      mobile: customerMobile,
      currAddStreet: customerAddress ? customerAddress : ``,
      currAddTown: "",
      currAddState: "",
      currAddPinCode: "",
      perAddStreet: customerAddress ? customerAddress : ``,
      perAddTown: "",
      perAddState: "",
      perAddPinCode: "",
    };
    try {
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message === "email already exist") {
        alert("email already exist"); // setAlertType("Email Already Exists, Try Login or use another email");
      } else {
        // alert("added Successfully");
        setSelectedCustomerEdit(false);
        setSelectedCustomer(data.data);
        // console.log(data, "newCsData");
      }
    } catch (error) {
      console.error(error);
      alert("fail");
      alert();
    }
  };
  const editDiscountPrices = () => {
    setDiscountAmount(0);
    let totalAmountPaying = allSelectedProducts.reduce(
      (total, product) =>
        total +
        parseFloat(product.finalPrice) +
        parseFloat(product.totalGstAmount),
      0
    );
    let amountToDeduct =
      parseFloat(totalAmountPaying) - parseFloat(totalPayableAmount);

    let totalAdjustment = 0;
    let updatedProductList = [...allSelectedProducts];
    // Check if there are products with non-zero MRP
    if (allSelectedProducts.some((x) => x.mrp !== 0)) {
      // Sort products by descending MRP
      updatedProductList.sort((a, b) => b.mrp - a.mrp);

      // Deduct amount from products in descending order of MRP
      for (let i = 0; i < updatedProductList.length; i++) {
        if (amountToDeduct > 0) {
          const deduction = Math.min(amountToDeduct, updatedProductList[i].mrp);
          updatedProductList[i].mrp -= deduction;
          updatedProductList[i].finalPrice = (
            (updatedProductList[i].mrp * 100) /
            103
          ).toFixed(2);
          updatedProductList[i].totalGstAmount = (
            (updatedProductList[i].mrp * 3) /
            103
          ).toFixed(2);
          totalAdjustment += deduction;
          amountToDeduct -= deduction;
        } else {
          break;
        }
      }
    }

    // Distribute any remaining amount among the products
    if (amountToDeduct > 0 && updatedProductList.length > 0) {
      const totalOriginalAmount = updatedProductList.reduce(
        (total, product) =>
          total +
          parseFloat(product.finalPrice) +
          parseFloat(product.totalGstAmount),
        0
      );
      //   setDiscountAmount(0);
      updatedProductList = updatedProductList.map((product) => {
        const originalAmount =
          parseFloat(product.finalPrice) + parseFloat(product.totalGstAmount);
        const adjustment =
          (originalAmount / totalOriginalAmount) * amountToDeduct;
        // console.log(
        //   typeof parseFloat(product.finalPrice),
        //   "product.finalPrice"
        // );
        // console.log(typeof product.totalGstAmount, "product.totalGstAmount");
        // console.log(
        //   typeof parseFloat(totalPayableAmount),
        //   "totalPayableAmount"
        // );
        const deductAmount = parseFloat(
          parseFloat(product.finalPrice) +
            parseFloat(product.totalGstAmount) -
            parseFloat(totalPayableAmount)
        );
        const newTotalGstAmount =
          parseFloat(product.totalGstAmount) - parseFloat(deductAmount) * 0.03;
        const newFinalPrice = (parseFloat(totalPayableAmount) * 100) / 103;
        const newMakingPerGram =
          parseFloat(product.making) - parseFloat(deductAmount);
        // console.log(totalPayableAmount, "totalPayableAmount");
        // console.log(typeof deductAmount, "deductAmount");
        // console.log(newTotalGstAmount, "newTotalGstAmount");
        // console.log(newFinalPrice, "newFinalPrice");
        // console.log(newMakingPerGram, "newMakingPerGram");
        totalAdjustment += adjustment;

        return {
          ...product,
          finalPrice: newFinalPrice,
          making: newMakingPerGram,
          totalGstAmount: newTotalGstAmount,
        };
      });

      amountToDeduct -= totalAdjustment;
    }

    // Now, updatedProductList contains the updated products with discounted prices
    // console.log("Updated Product List with Discounts", updatedProductList);
    setAllSelectedProducts(updatedProductList);
  };
  useEffect(() => {
    editDiscountPrices();
  }, [updatePrices]);
  // const editItem = (id) => {
  //   setOpenEditBox(true);
  //   // document.body.classList.add("body-no-scroll");
  // };
  const editItem = (product) => {
    setOpenEditBox(true);
    // if (!allSelectedProducts.some((x) => x.id === selectedProduct.id)) {
    setOpenEditProduct(product);

    // } else {
    // alert("Product Already added");
    // }
  };
  const closeEditItem = () => {
    setOpenEditBox(false);
    // document.body.classList.add("body-no-scroll");
  };
  const handleInputChange2 = (e, property) => {
    const { value } = e.target;
    if (selectedProduct) {
      const updatedProduct = {
        ...openEditProduct,
        [property]: e.target.value,
      };
      const grosswt = parseFloat(updatedProduct.grosswt) || 0;
      const stoneWeight = parseFloat(updatedProduct.stoneWeight) || 0;
      const netWt = parseFloat(updatedProduct.netWt) || 0;
      if (property === "grosswt" && !isNaN(value)) {
        updatedProduct.netWt = (parseFloat(value) - stoneWeight).toFixed(3);
        // calculateFinalPrice(selectedProduct);
      }
      if (property === "stoneWeight" && !isNaN(value)) {
        updatedProduct.netWt = (grosswt - parseFloat(value)).toFixed(3);
      }
      if (property === "netWt" && !isNaN(value)) {
        updatedProduct.grosswt = (
          parseFloat(stoneWeight) + parseFloat(value)
        ).toFixed(3);
      }
      // console.log(updatedProduct, "updatedProduct");
      if (property === "todaysRate" && !isNaN(value)) {
        updatedProduct.tblPurity.todaysRate = parseFloat(value).toFixed(0);
      }

      if (
        property === "netWt" ||
        property === "grosswt" ||
        property === "stonewt" ||
        property === "netwt" ||
        property === "stoneWeight" ||
        property === "making_per_gram" ||
        property === "making_Percentage" ||
        property === "making_Fixed_Amt" ||
        property === "todaysRate" ||
        property === "hallmark_amt" ||
        property === "making_Fixed_Wastage"
      ) {
        let netGoldRate =
          (parseFloat(updatedProduct.netWt) *
            parseFloat(updatedProduct.tblPurity.todaysRate)) /
          10;
        let makingCharges1 =
          parseFloat(updatedProduct.netWt) *
          parseFloat(updatedProduct.making_per_gram);
        let makingCharges2 =
          (parseFloat(netGoldRate) *
            parseFloat(updatedProduct.making_Percentage)) /
          100;
        let makingCharges3 = parseFloat(updatedProduct.making_Fixed_Amt);
        let makingCharges4 =
          (parseFloat(updatedProduct.tblPurity.todaysRate) *
            parseFloat(updatedProduct.making_Fixed_Wastage)) /
          10;
        let GST = 0.03;

        let grossTotalRate =
          parseFloat(netGoldRate) +
          parseFloat(makingCharges1) +
          parseFloat(makingCharges2) +
          parseFloat(makingCharges3) +
          parseFloat(makingCharges4) +
          parseFloat(updatedProduct.hallmark_amt) +
          parseFloat(updatedProduct.stoneAmount);
        let GSTAdded = parseFloat(GST) * parseFloat(grossTotalRate);
        let finalPrice = parseFloat(grossTotalRate) + parseFloat(GSTAdded);

        // Calculate total making charges
        let totalMakingCharges =
          parseFloat(makingCharges1) +
          parseFloat(makingCharges2) +
          parseFloat(makingCharges3) +
          parseFloat(makingCharges4);

        // console.log(netGoldRate, "netGoldRate");
        if (updatedProduct.mrp == 0 || updatedProduct.mrp == "") {
          updatedProduct.finalPrice = parseFloat(grossTotalRate).toFixed(3);
          updatedProduct.making = totalMakingCharges;
          updatedProduct.totalGstAmount = GSTAdded;
        } else {
          // updatedProduct = {
          //   ...updatedProduct,
          //   finalPrice: parseFloat(grossTotalRate).toFixed(3),
          //   //   finalPrice: parseFloat(finalPrice).toFixed(3),
          //   making: totalMakingCharges,
          //   totalGstAmount: GSTAdded,
          // };

          updatedProduct.finalPrice = 0;
          updatedProduct.making = 0;
          updatedProduct.totalGstAmount = 0;
          //   finalPrice: parseFloat(finalPrice).toFixed(3),
          // }
          setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
          setOpenEditProduct(updatedProduct);
        }
        // Update selectedProduct with additional properties and calculated price
        if (updatedProduct.purchase) {
          calculatePurchasePrice(updatedProduct);
        }
        setSelectedProduct(updatedProduct); // Update the selected product
        setOpenEditProduct(updatedProduct);
        // calculateFinalPrice(updatedProduct);

        setSelectedProductPrice(parseFloat(finalPrice).toFixed(3));
      }
      // Update the specific product in allSelectedProducts array without changing its position
      const updatedProducts = allSelectedProducts.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      );
      // Update the state with the modified products array
      setOpenEditProduct(updatedProduct);
      setAllSelectedProducts(updatedProducts);

      // Update the openEditProduct state
    }
  };

  const addPayment = () => {
    // Check if both payment mode and amount are provided
    if (paymentOptions && paymentAmount >= 0 && paymentType === "Paid") {
      // Update the payments array with new payment mode and amount
      if (
        paymentOptions === "Metal to Cash" ||
        paymentOptions === "Cash to Metal"
      ) {
        setPayments([
          ...payments,
          {
            mode: paymentOptions,
            amount: paymentAmount,
            fineGold: parseFloat(paymentGold),
            fineSilver: parseFloat(paymentSilver),
            deductGold: deductGold,
            deductSilver: deductSilver,
            paymentType: paymentType,
            goldRate: metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? metalPaymentOption.fineRate
              : 0,
            silverRate: !metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? metalPaymentOption.fineRate
              : 0,
            goldAmount: metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? metalPaymentOption.totalAmount
              : 0,
            silverAmount: !metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? metalPaymentOption.totalAmount
              : 0,
            paymentDescription: paymentDescription,
          },
        ]);
      } else {
        setPayments([
          ...payments,
          {
            mode: !paymentOptions.toLowerCase().includes("advance")
              ? paymentOptions
              : advanceType,
            amount: !paymentOptions.toLowerCase().includes("advance")
              ? paymentAmount
              : advanceAmount,
            fineGold: 0,
            fineSilver: 0,
            deductGold: 0,
            deductSilver: 0,
            paymentType: paymentType,
            goldRate: 0,
            silverRate: 0,
            goldAmount: 0,
            silverAmount: 0,
            paymentDescription: paymentDescription,
          },
        ]);
      }
      if (!paymentOptions.toLowerCase().includes("advance")) {
        setGrandTotal(parseInt(grandTotal) - parseInt(paymentAmount));
        setPaymentAmount(parseInt(grandTotal) - parseInt(paymentAmount));
      } else if (
        paymentOptions.toLowerCase().includes("advance") &&
        advanceType === "Deduct Advance"
      ) {
        setSelectedCustomer({
          ...selectedCustomer,
          advanceAmt:
            parseFloat(selectedCustomer.advanceAmt) - parseFloat(advanceAmount),
        });
        setGrandTotal(parseInt(grandTotal) - parseInt(advanceAmount));
        setPaymentAmount(parseInt(grandTotal) - parseInt(advanceAmount));
        setAdvanceAmount(0);
      } else {
        setGrandTotal(parseInt(grandTotal));
        setPaymentAmount(parseInt(grandTotal));
        setAdvanceAmount(0);
      }
      // Clear the input fields
      // setPaymentOptions("Cash");
    } else if (
      paymentOptions &&
      paymentAmount > 0 &&
      paymentType === "Receive"
    ) {
      // Update the payments array with new payment mode and amount
      if (
        paymentOptions === "Metal to Cash" ||
        paymentOptions === "Cash to Metal"
      ) {
        setPayments([
          ...payments,
          {
            mode: paymentOptions,
            amount: -paymentAmount,
            fineGold: parseFloat(-paymentGold),
            fineSilver: parseFloat(-paymentSilver),
            deductGold: parseFloat(-deductGold),
            deductSilver: parseFloat(-deductSilver),
            paymentType: paymentType,
            goldRate: metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.fineRate
              : 0,
            silverRate: !metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.fineRate
              : 0,
            goldAmount: metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.totalAmount
              : 0,
            silverAmount: !metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.totalAmount
              : 0,
            paymentDescription: paymentDescription,
          },
        ]);
      } else {
        setPayments([
          ...payments,
          {
            mode: paymentOptions,
            amount: -paymentAmount,
            fineGold: 0,
            fineSilver: 0,
            deductGold: 0,
            deductSilver: 0,
            paymentType: paymentType,
            goldRate: 0,
            silverRate: 0,
            goldAmount: 0,
            silverAmount: 0,
            paymentDescription: paymentDescription,
          },
        ]);
      }
      setGrandTotal(parseInt(grandTotal) - parseInt(-paymentAmount));
      // Clear the input fields
      // setPaymentOptions("Cash");
      setPaymentAmount(
        Math.abs(parseInt(grandTotal) - parseInt(-paymentAmount))
      );
    } else if (
      paymentOptions &&
      paymentAmount < 0 &&
      paymentType === "Receive"
    ) {
      // Update the payments array with new payment mode and amount
      if (
        paymentOptions === "Cash to Metal" ||
        paymentOptions === "Metal to Cash"
      ) {
        setPayments([
          ...payments,
          {
            mode: paymentOptions,
            amount: -paymentAmount,
            fineGold: parseFloat(-paymentGold),
            fineSilver: parseFloat(-paymentSilver),
            deductGold: parseFloat(-deductGold),
            deductSilver: parseFloat(-deductSilver),
            paymentType: paymentType,
            goldRate: metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.fineRate
              : 0,
            silverRate: !metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.fineRate
              : 0,
            goldAmount: metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.totalAmount
              : 0,
            silverAmount: metalPaymentOption.optionSelected
              .toLowerCase()
              .includes("gold")
              ? -metalPaymentOption.totalAmount
              : 0,
            paymentDescription: paymentDescription,
          },
        ]);
      } else {
        setPayments([
          ...payments,
          {
            mode: paymentOptions,
            amount: -paymentAmount,
            fineGold: 0,
            fineSilver: 0,
            deductGold: 0,
            deductSilver: 0,
            paymentType: paymentType,
            goldRate: 0,
            silverRate: 0,
            goldAmount: 0,
            silverAmount: 0,
            paymentDescription: paymentDescription,
          },
        ]);
      }
      setGrandTotal(parseInt(grandTotal) - parseInt(paymentAmount));
      // Clear the input fields
      // setPaymentOptions("Cash");
      setPaymentAmount(parseInt(grandTotal) - parseInt(paymentAmount));
    }
    setTotalPayableGold(totalPayableGold - deductGold);
    setTotalPayableSilver(totalPayableSilver - deductSilver);
    setPaymentDescription("");
    // setMetalPaymentOption({
    //   optionSelected: "Gold",
    //   fineRate: 0,
    //   fineWt: 0,
    //   totalAmount: 0,
    //   deductGold: 0,
    //   deductSilver: 0,
    //   goldRate: 0,
    //   silverRate: 0,
    //   goldAmount: 0,
    //   silverAmount: 0,
    // });
    // setPaymentOptions("Cash");
    setPaymentGold(0);
    setPaymentSilver(0);
  };

  const deletePayment = (index) => {
    // Get the amount of the payment to be deleted
    setPaymentOptions(payments[index].mode);
    const deletedAmount = parseFloat(payments[index].amount);
    const deletedGoldWeight = parseFloat(payments[index].deductGold);
    const deletedSilverWeight = parseFloat(payments[index].deductSilver);

    // Remove the payment at the specified index
    const updatedPayments = [...payments];
    updatedPayments.splice(index, 1);

    // Calculate the new grand total by subtracting the deleted amount

    // Update the payments array and grand total state
    setPayments(updatedPayments);
    const newGrandTotal =
      grandTotal +
      // (payments[index].paymentType === "Receive"
      deletedAmount;
    // : -deletedAmount);
    // (payments[index].paymentType === "Receive"
    //   ? +deletedAmount
    //   : -deletedAmount);
    if (payments[index].mode === "Advance Received") {
      null;
    } else if (payments[index].mode === "Deduct Advance") {
      setSelectedCustomer({
        ...selectedCustomer,
        advanceAmt:
          parseFloat(selectedCustomer.advanceAmt) +
          parseFloat(payments[index].amount),
      });
      setGrandTotal(newGrandTotal);
      const remainingGoldWeight = totalPayableGold + deletedGoldWeight;
      const remainingSilverWeight = totalPayableSilver + deletedSilverWeight;
      setTotalPayableGold(remainingGoldWeight);
      setTotalPayableSilver(remainingSilverWeight);
      setPaymentAmount(Math.abs(parseInt(newGrandTotal)));
    } else {
      setGrandTotal(newGrandTotal);
      const remainingGoldWeight = totalPayableGold + deletedGoldWeight;
      const remainingSilverWeight = totalPayableSilver + deletedSilverWeight;
      setTotalPayableGold(remainingGoldWeight);
      setTotalPayableSilver(remainingSilverWeight);
      setPaymentAmount(Math.abs(parseInt(newGrandTotal)));
    }

    // let resetAllProductPrices = allPurchaseData.map((product) => {
    //   return {
    //     ...product,
    //     paidPrice: 0,
    //   };
    // });;
    // setAllPurchaseData(resetAllProductPrices);
  };

  // Convert payments array to a comma-separated string whenever you need it
  const paymentsString = payments
    .map((payment) => `${payment.mode}:${payment.amount}`)
    .join(",");
  // Function to calculate total payment amount
  const calculateTotalAmount = () => {
    // Use reduce to sum all payment amounts
    const totalPaidAmount = payments.reduce(
      (total, payment) =>
        total +
        (payment.mode !== "Advance Received" ? parseFloat(payment.amount) : 0),
      0
    );

    return totalPaidAmount;
  };

  // Render total payment amount
  const totalPaidAmount = calculateTotalAmount();

  const resetAllFields = () => {
    // setProductsLoading(true);
    // fetchAllProducts();
    setSelectedCustomer(null);
    setSelectedProduct([]);
    setAllSelectedProducts([]);
    setPaymentAmount(0);
    setPayments([]);
    setSelectedSalesEmployee("");
    window.scrollTo(0, 0);
  };

  // let selectedCategoryId = "";
  // let selectedCategoryName = "";
  // let selectedPurityName = "";
  // let selectedPurityRate = "";
  // console.log(purchaseProduct, "purchaseProduct");

  const handleInputChangePurchase = (e) => {
    const { name, value } = e.target;

    console.log(name, "name");
    console.log(name, "name");
    console.log(value, "value");
    console.log(value, "value");
    const updatedProduct = purchaseProduct; // Create a copy of the purchaseProduct object
    // Update the edited data in the updatedProduct object
    if (name === "CategoryId") {
      const [selectedCategoryId, selectedCategoryName] = value.split(",");
      // (updatedProduct.category_id = selectedCategoryId),
      updatedProduct.CategoryName = selectedCategoryName;

      // setSelectedCategory(selectedCategoryName),
      (updatedProduct.CategoryId = selectedCategoryId),
        (updatedProduct.CategoryName = selectedCategoryName);
    } else if (name === "ProductName") {
      const [selectedProductId, selectedProductName] = value.split(",");
      // setSelectedProductType(selectedProductName),
      (updatedProduct.ProductTypeId = selectedProductId),
        (updatedProduct.ProductName = selectedProductName);
    } else if (name === "GrossWt") {
      updatedProduct.NetWt = parseFloat(
        parseFloat(value) - parseFloat(updatedProduct.StoneWt)
      ).toFixed(3);
      updatedProduct.GrossWt = value;
    } else if (name === "StoneWt") {
      updatedProduct.NetWt = parseFloat(
        parseFloat(updatedProduct.GrossWt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.StoneWt = value;
    } else if (name === "NetWt") {
      updatedProduct.StoneWt = parseFloat(
        parseFloat(updatedProduct.GrossWt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.NetWt = value;
    } else if (name === "Purity") {
      let fineWeight =
        (parseFloat(updatedProduct.NetWt) * parseFloat(value)) / 100;
      let wastageWeight =
        (parseFloat(updatedProduct.Wastage) *
          parseFloat(updatedProduct.NetWt)) /
        100;
      let totalFineWastageWt =
        parseFloat(fineWeight) + parseFloat(wastageWeight);
      updatedProduct.Purity = value !== "" ? value : 0;
      updatedProduct.FineWt = parseFloat(fineWeight);
      updatedProduct.FineWastageWt = parseFloat(totalFineWastageWt);
      updatedProduct.TotalItemAmt = parseFloat(totalFineWastageWt);
    } else if (name === "Wastage") {
      let fineWeight = parseFloat(updatedProduct.FineWt);
      let wastageWeight =
        (parseFloat(value) * parseFloat(updatedProduct.NetWt)) / 100;
      let totalFineWastageWt =
        parseFloat(fineWeight) + parseFloat(wastageWeight);
      //   updatedProduct.Purity = parseFloat(value);
      updatedProduct.Wastage = value;
      updatedProduct.FineWt = parseFloat(fineWeight).toFixed(3);
      updatedProduct.FineWastageWt = parseFloat(totalFineWastageWt).toFixed(3);
      updatedProduct.TotalItemAmt = parseFloat(totalFineWastageWt).toFixed(3);
      updatedProduct.MakingFixedWastage =
        selectedSkuName !== ""
          ? parseFloat(selectedSku.makingFixedWastage).toFixed(3)
          : "0";
    } else if (name === "Rate") {
      let fineWeight = parseFloat(updatedProduct.FineWt);
      let wastageWeight =
        parseFloat(updatedProduct.Wastage) / parseFloat(updatedProduct.NetWt);
      let totalFineWastageWt =
        parseFloat(fineWeight) + parseFloat(wastageWeight);
      updatedProduct.Rate = parseFloat(value) !== 0 ? value : 0;
      if (convertAmount) {
        updatedProduct.TotalItemAmt = parseFloat(
          (parseFloat(updatedProduct.TotalItemAmt) * parseFloat(value)) / 10
        ).toFixed(3);
      } else {
        updatedProduct.TotalItemAmt = parseFloat(totalFineWastageWt).toFixed(3);
      }
    } else if (name === "purityRate") {
      const [selectedPurityName, selectedPurityRate] = value.split(",");
      setSelectedPurity(selectedPurityName);
      updatedProduct.purity = selectedPurityName;
      updatedProduct.GoldRate = selectedPurityRate;
      updatedProduct.purityRate = selectedPurityRate;
    } else if (name === "GoldRate") {
      updatedProduct.GoldRate = parseFloat(value);
      updatedProduct.purityRate = parseFloat(value);
    } else if (name === "ProductName") {
      updatedProduct.ProductName = value;
    } else {
      updatedProduct[name] = value;
    }

    // Set the state of the purchaseProduct object with the updatedProduct object
    setPurchaseProduct(updatedProduct);

    // Calculate purchase price based on the updatedProduct object
    calculatePurchasePrice(updatedProduct);

    // Rest of the function logic...
  };

  useEffect(() => {
    const updatedProduct = purchaseProduct;
    // if (convertAmount === true) {
    updatedProduct.TotalItemAmt =
      (parseFloat(purchaseProduct.Rate) / 10) *
      parseFloat(purchaseProduct.FineWastageWt);
    // } else {
    // updatedProduct.TotalItemAmt = purchaseProduct.FineWastageWt;
    // }
    setPurchaseProduct(updatedProduct);
    calculatePurchasePrice(updatedProduct);
  }, [convertAmount]);
  //
  // console.log(purchaseProduct, "purchaseProduct");
  // console.log(purchaseProduct, "purchaseProduct");
  const handleInputChangeUnlabel = (e) => {
    const { name, value } = e.target;
    const updatedProduct = { ...unlabelProduct }; // Create a copy of the purchaseProduct object

    // Update the edited data in the updatedProduct object
    if (name === "category_id") {
      const [selectedCategoryId, selectedCategoryName] = value.split(",");
      // setSelectedCategory(selectedCategoryName),
      (updatedProduct.category_id = selectedCategoryId),
        (updatedProduct.CategoryName = selectedCategoryName);
      (updatedProduct.productTypeId = ""),
        (updatedProduct.product_Name = ""),
        (updatedProduct.collectionName = ""),
        (updatedProduct.selectedUnlabelId = 0),
        (updatedProduct.grosswt = "0"),
        (updatedProduct.netwt = "0"),
        (updatedProduct.stonewt = "0"),
        (updatedProduct.GoldRate = "0"),
        (updatedProduct.Finepercent = "0"),
        (updatedProduct.making = "0"),
        (updatedProduct.NetAmt = "0"),
        (updatedProduct.GSTAmount = "0"),
        (updatedProduct.TotalAmt = "0"),
        (updatedProduct.Quantity = "1"),
        (updatedProduct.unlabelAmount = "0"),
        (updatedProduct.purityRate = "0"),
        (updatedProduct.purity = "100%"),
        (updatedProduct.finalPrice = "0"),
        (updatedProduct.totalGstAmount = "0"),
        (updatedProduct.purchase = false),
        (updatedProduct.unlabel = true),
        (updatedProduct.tblPurity.todaysRate = "0");
    } else if (name === "grosswt") {
      updatedProduct.netwt = parseFloat(
        parseFloat(value) - parseFloat(updatedProduct.stonewt)
      ).toFixed(3);
      updatedProduct.grosswt = value;
    } else if (name === "stonewt") {
      updatedProduct.netwt = parseFloat(
        parseFloat(updatedProduct.grosswt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.stonewt = value;
    } else if (name === "netwt") {
      updatedProduct.stonewt = parseFloat(
        parseFloat(updatedProduct.grosswt) - parseFloat(value)
      ).toFixed(3);
      updatedProduct.netwt = value;
    } else if (name === "purityRate") {
      const [selectedPurityName, selectedPurityRate] = value.split(",");
      setSelectedPurity(selectedPurityName);
      updatedProduct.purity = selectedPurityName;
      updatedProduct.GoldRate = selectedPurityRate;
      updatedProduct.purityRate = selectedPurityRate;
    } else if (name === "GoldRate") {
      updatedProduct.GoldRate = parseFloat(value);
      updatedProduct.purityRate = parseFloat(value);
    } else if (name === "productTypeId") {
      const [productIdNumber, productTypeName] = value.split(",");
      updatedProduct.productTypeId = parseInt(productIdNumber);
      updatedProduct.product_Name = productTypeName;
    } else if (name === "collectionName") {
      const [selectedUnlabelId, collectionNameSelected, collectionIdSelected] =
        value.split(",");
      updatedProduct.collectionName = collectionNameSelected;
      updatedProduct.collectionId = collectionIdSelected;
      updatedProduct.selectedUnlabelId = selectedUnlabelId;
      updatedProduct.id = parseInt(selectedUnlabelId);
      let availableQty = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].quantity;
      let availableGrossWt = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].grosswt;
      let availableNetWt = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].netWt;
      let availableFineWastage = allUnlabelList.filter(
        (x) => x.id === parseInt(selectedUnlabelId)
      )[0].fine_wastage;

      updatedProduct.MaxQuantity = availableQty;
      updatedProduct.grosswt = availableGrossWt;
      updatedProduct.netwt = availableNetWt;
      updatedProduct.MaxGrossWt = availableGrossWt;
      updatedProduct.MaxNetWt = availableNetWt;
      updatedProduct.Finepercent = availableFineWastage;
    } else {
      updatedProduct[name] = value;
    }

    // Set the state of the purchaseProduct object with the updatedProduct object
    setUnlabelProduct(updatedProduct);

    // Calculate purchase price based on the updatedProduct object
    calculateUnlabelPrice(updatedProduct);

    // Rest of the function logic...
  };
  // useEffect(() => {
  //   calculatePurchasePrice();
  // }, [purchaseProduct]);
  const calculatePurchasePrice = (purchaseProduct) => {
    let FineRate =
      (parseFloat(purchaseProduct.FineWastageWt) *
        parseFloat(purchaseProduct.Rate)) /
      10;
    let netRate = parseFloat(
      parseFloat(FineRate) * parseFloat(purchaseProduct.NetWt)
    ).toFixed(3);
    let makingCharges1 =
      parseFloat(purchaseProduct.NetWt) *
      parseFloat(purchaseProduct.MakingPerGram);
    let makingCharges2 =
      (parseFloat(netRate) * parseFloat(purchaseProduct.MakingPercentage)) /
      1000;
    let makingCharges3 = parseFloat(purchaseProduct.MakingFixedAmt);
    let makingCharges4 =
      (parseFloat(purchaseProduct.Rate) *
        parseFloat(purchaseProduct.MakingFixedWastage)) /
      10;
    let totalMakingCharges =
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4) +
      parseFloat(purchaseProduct.StoneAmt);
    let gstRate = parseFloat(FineRate) * 0.03;
    let gstRateOnMaking = parseFloat(totalMakingCharges) * 0.03;
    let totalRate = parseFloat(
      parseFloat(FineRate) + parseFloat(totalMakingCharges)
    );
    let totalGstRate = parseFloat(
      parseFloat(gstRate) + parseFloat(gstRateOnMaking)
    );

    if (convertAmount) {
      setPurchaseProduct({
        ...purchaseProduct,
        making: totalMakingCharges,
        // TotalItemAmt: convertAmount
        //   ? totalRate
        //   : parseInt(totalMakingCharges) !== 0
        //   ? parseFloat(totalMakingCharges).toFixed(3)
        //   : parseFloat(purchaseProduct.FineWastageWt).toFixed(3),
        TotalItemAmt: convertAmount ? totalRate : totalMakingCharges,
        // ? parseInt(totalMakingCharges) !== 0
        // : parseFloat(totalMakingCharges).toFixed(3),
        // : parseFloat(purchaseProduct.FineWastageWt).toFixed(3),
        NetAmt: netRate,
        GSTAmount: gstRate,
        TotalAmt: totalRate,
        toAmount: convertAmount,
        PurchaseAmount: totalRate,
        //   finalPrice: `${netRate + gstRate + totalMakingCharges}`,
        // finalPrice: `${totalRate}`,
        finalPrice: `${totalRate}`,
        // totalGstAmount: `${gstRate}`,
        totalGstAmount: `${totalGstRate}`,
        BalanceGold: 0,
        BalanceSilver: 0,
        FineGold:
          purchaseProduct.CategoryName &&
          purchaseProduct.CategoryName !== "" &&
          purchaseProduct.CategoryName.toLowerCase().includes("gold")
            ? purchaseProduct.FineWastageWt
            : "0",
        FineSilver:
          purchaseProduct.CategoryName &&
          purchaseProduct.CategoryName !== "" &&
          purchaseProduct.CategoryName.toLowerCase().includes("silver")
            ? purchaseProduct.FineWastageWt
            : "0",
      });
    } else {
      setPurchaseProduct({
        ...purchaseProduct,
        making: totalMakingCharges,
        // TotalItemAmt: convertAmount
        //   ? totalRate
        //   : parseInt(totalMakingCharges) !== 0
        //   ? parseFloat(totalMakingCharges).toFixed(3)
        //   : parseFloat(purchaseProduct.FineWastageWt).toFixed(3),
        TotalItemAmt: convertAmount ? totalRate : totalMakingCharges,
        // : parseInt(totalMakingCharges) !== 0
        // ? parseFloat(totalMakingCharges).toFixed(3)
        // : parseFloat(purchaseProduct.FineWastageWt).toFixed(3),
        NetAmt: 0,
        GSTAmount: 0,
        TotalAmt: 0,
        toAmount: convertAmount,
        PurchaseAmount: 0,
        //   finalPrice: `${netRate + gstRate + totalMakingCharges}`,
        finalPrice: convertAmount
          ? `${totalRate}`
          : parseInt(totalMakingCharges) !== 0
          ? `${parseFloat(totalMakingCharges).toFixed(3)}`
          : `${0}`,
        totalGstAmount: convertAmount
          ? `${gstRate}`
          : parseInt(totalMakingCharges) !== 0
          ? `${parseFloat(gstRateOnMaking).toFixed(3)}`
          : // : `${parseFloat(purchaseProduct.FineWastageWt).toFixed(3)}`,
            `${0}`,
        // totalGstAmount: `${0}`,
        BalanceGold:
          !convertAmount &&
          purchaseProduct.CategoryName !== "" &&
          purchaseProduct.CategoryName &&
          purchaseProduct.CategoryName.toLowerCase().includes("gold")
            ? purchaseProduct.FineWastageWt
            : 0,
        BalanceSilver:
          !convertAmount &&
          purchaseProduct.CategoryName &&
          purchaseProduct.CategoryName !== "" &&
          purchaseProduct.CategoryName.toLowerCase().includes("silver")
            ? purchaseProduct.FineWastageWt
            : 0,
        FineGold:
          purchaseProduct.CategoryName &&
          purchaseProduct.CategoryName !== "" &&
          purchaseProduct.CategoryName.toLowerCase().includes("gold")
            ? purchaseProduct.FineWastageWt
            : "0",
        FineSilver:
          purchaseProduct.CategoryName &&
          purchaseProduct.CategoryName !== "" &&
          purchaseProduct.CategoryName.toLowerCase().includes("silver")
            ? purchaseProduct.FineWastageWt
            : "0",
      });
    }

    // setGrandTotal(
    //   parseInt(grandTotal) - parseInt(purchaseProduct.PurchaseAmount)
    // );
  };

  const calculateUnlabelPrice = (unlabelProduct) => {
    let FineRate = (100 * parseFloat(unlabelProduct.purityRate)) / 1000;
    let netRate = parseFloat(
      parseFloat(FineRate) * parseFloat(unlabelProduct.netwt)
    ).toFixed(3);
    let totalRate = parseFloat(parseFloat(netRate));

    let netGoldRate =
      (parseFloat(unlabelProduct.netwt) * parseFloat(unlabelProduct.GoldRate)) /
      10;
    let makingCharges1 =
      parseFloat(unlabelProduct.netwt) *
      parseFloat(unlabelProduct.making_per_gram);
    let makingCharges2 =
      (parseFloat(netGoldRate) * parseFloat(unlabelProduct.making_Percentage)) /
      100;
    let makingCharges3 = parseFloat(unlabelProduct.making_Fixed_Amt);
    let makingCharges4 =
      (parseFloat(unlabelProduct.GoldRate) *
        parseFloat(unlabelProduct.making_Fixed_Wastage)) /
      10;

    let totalMaking =
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4);
    let gstRate = (parseFloat(netRate) + parseFloat(totalMaking)) * 0.03;
    setUnlabelProduct({
      ...unlabelProduct,
      NetAmt: parseFloat(netRate) + parseFloat(totalMaking),
      making: parseFloat(totalMaking),
      GSTAmount: gstRate,
      TotalAmt:
        parseFloat(netRate) + parseFloat(totalMaking) + parseFloat(gstRate),
      unlabelAmount: parseFloat(
        parseFloat(netRate) + parseFloat(gstRate) + parseFloat(totalMaking)
      ).toFixed(3),
      finalPrice: `${parseFloat(netRate) + parseFloat(totalMaking)}`,
      totalGstAmount: `${gstRate}`,
    });

    // setOldGoldAmount(parseFloat(totalRate).toFixed(0));
    // setGrandTotal(grandTotal - totalRate);
    // setPaymentAmount(grandTotal - totalRate);
    // Clear the input fields
    // setPaymentOptions("Cash");

    // setGrandTotal(
    //   parseInt(grandTotal) - parseInt(purchaseProduct.PurchaseAmount)
    // );
  };
  // console.log(allProductTypes);
  // console.log(purchaseProduct);

  const addPurchaseProductToList = (selectedProduct) => {
    setAllSelectedProducts((prevItems) => [...prevItems, selectedProduct]);
    setLabelName("");
    setSelectedProduct([]);
    setCategoryName("");
    setProductName("");
    setCollectionName("");
    setPurityType("");
    setProductQty("");
    setSelectedProductPrice(0);
    // scrollToCenter("adminInvoiceSelectLabelBox");
    setPurchaseProduct({
      CategoryName: "",
      ProductName: "",
      BalanceGold: "0",
      BalanceSilver: "0",
      Wastage: "0",
      FineGold: "0",
      FineSilver: "0",
      id: 0,
      CategoryId: "",
      ProductTypeId: "",
      GrossWt: "0",
      NetWt: "0",
      StoneWt: "0",
      GoldRate: "0",
      Finepercent: "0",
      making: "0",
      NetAmt: "0",
      GSTAmount: "0",
      TotalAmt: "0",
      Quantity: "1",
      PurchaseAmount: "0",
      PurchaseEntryNo: "",
      purityRate: "0",
      Purity: "0",
      finalPrice: "0",
      totalGstAmount: "0",
      purchase: true,
      unlabel: false,
      MakingFixedAmt: "0",
      MakingPercentage: "0",
      MakingPerGram: "0",
      MakingFixedWastage: "0",
      // Wastage:"0",
      Rate: "0",
      toAmount: convertAmount,
      StoneAmt: "0",
      TotalItemAmt: "0",
      FineWt: "0",
      FineWastageWt: "0",
      RDPurchaseId: "",
      tblPurity: { todaysRate: "0" },
    });
    setUnlabelProduct({
      CategoryName: "",
      category_id: "",
      productTypeId: "",
      product_Name: "",
      collectionName: "",
      selectedUnlabelId: 0,
      grosswt: "0",
      netwt: "0",
      stonewt: "0",
      GoldRate: "0",
      Finepercent: "0",
      making_Fixed_Amt: "0",
      making_Percentage: "0",
      making_per_gram: "0",
      making_Fixed_Wastage: "0",
      making: "0",
      NetAmt: "0",
      MaxQuantity: "0",
      MaxGrossWt: "0",
      MaxNetWt: "0",
      GSTAmount: "0",
      TotalAmt: "0",
      Quantity: "1",
      unlabelAmount: "0",
      purityRate: "0",
      purity: "100%",
      finalPrice: "0",
      totalGstAmount: "0",
      purchase: false,
      unlabel: true,
      tblPurity: { todaysRate: "0" },
    });
    setActive("Sell");
    // setSelectedSkuName("");
    // setSelectedSku([]);
    setSelectedProduct([]);
    // setSelectedCategory("");
    setSelectedProductType("");
    setSelectedSku([]);
    setSelectedSkuName("");
    setSelectedCategory("");
    setSelectedProductType("");
    console.log("here");
    setConvertAmount(true);
    // } else {
    // alert("Product Already added");
    // setSelectedProduct([]);
    // }
  };

  useEffect(() => {
    const totalPurchaseAmount = allSelectedProducts
      .filter((x) => x.purchase === true)
      .reduce(
        (total, product) => total + parseFloat(product.PurchaseAmount),
        0
      );
    setOldGoldAmount(totalPurchaseAmount);
    setPurchaseProductList(
      allSelectedProducts.filter((x) => x.purchase === true)
    );
    setUnlabelProductList(
      allSelectedProducts.filter((x) => x.unlabel === true)
    );
    // console.log(purchaseProductList, "onlyPurchaseProducts");
  }, [allSelectedProducts]);
  const removePurchaseProductFromList = (index) => {
    const updatedProductList = allSelectedProducts.filter(
      (_, i) => i !== index
    );
    setAllSelectedProducts(updatedProductList);
  };
  // console.log(payments, "payments");
  // console.log(payments, "payments");
  const handleMetalPaymentOption = (a, b) => {
    const { value } = b.target;
    if (paymentOptions === "Metal to Cash") {
      let totalAmount = 0;
      if (
        metalPaymentOption.optionSelected !== "" &&
        metalPaymentOption.optionSelected.toLowerCase().includes("gold")
      ) {
        if (a == "Rate") {
          totalAmount = (value / 10) * metalPaymentOption.fineWt;
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineRate: value,
            totalAmount: totalAmount,
          });
          setPaymentGold(metalPaymentOption.fineWt);
          setPaymentAmount(totalAmount);
          setDeductGold(0);
        } else {
          totalAmount = (metalPaymentOption.fineRate / 10) * value;
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineWt: value,
            totalAmount: totalAmount,
          });
          setPaymentAmount(totalAmount);
          setPaymentGold(value);
          setDeductGold(0);
        }
      } else {
        if (a == "Rate") {
          totalAmount = (value / 10) * metalPaymentOption.fineWt;
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineRate: value,
            totalAmount: totalAmount,
          });
          setPaymentSilver(metalPaymentOption.fineWt);
          setPaymentAmount(totalAmount);
          setDeductSilver(0);
        } else {
          totalAmount = (metalPaymentOption.fineRate / 10) * value;
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineWt: value,
            totalAmount: totalAmount,
          });
          setPaymentAmount(totalAmount);
          setPaymentSilver(value);
          setDeductSilver(0);
        }
      }
    } else if (paymentOptions === "Cash to Metal") {
      let fineWt = 0;
      if (
        metalPaymentOption.optionSelected !== "" &&
        metalPaymentOption.optionSelected.toLowerCase().includes("gold")
      ) {
        if (a == "Amount") {
          fineWt = parseFloat(
            (value * 10) / metalPaymentOption.fineRate
          ).toFixed(3);
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineWt: fineWt !== "" ? fineWt : 0,
            totalAmount: value,
            deductGold: fineWt !== "" ? fineWt : 0,
            deductSilver: 0,
          });
          setPaymentAmount(0);
          setDeductGold(fineWt !== "" ? fineWt : 0);
          setPaymentGold(fineWt !== "" ? fineWt : 0);
          setDeductSilver(0);
          // setPaymentMetal();
        } else {
          fineWt = parseFloat(
            (metalPaymentOption.totalAmount * 10) / value
          ).toFixed(3);
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineWt: fineWt !== "" ? fineWt : 0,
            fineRate: value,
            deductGold: fineWt !== "" ? fineWt : 0,
            deductSilver: 0,
          });
          // setPaymentAmount(totalAmount);
          setDeductGold(fineWt !== "" ? fineWt : 0);
          setPaymentGold(fineWt !== "" ? fineWt : 0);
          setDeductSilver(0);
        }
      } else {
        if (a == "Amount") {
          fineWt = (
            parseFloat(value * 10) / metalPaymentOption.fineRate
          ).toFixed(3);
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineWt: fineWt !== "" ? fineWt : 0,
            totalAmount: value,
            deductGold: 0,
            deductSilver: fineWt !== "" ? fineWt : 0,
          });
          setPaymentAmount(0);
          setDeductSilver(fineWt !== "" ? fineWt : 0);
          setPaymentSilver(fineWt !== "" ? fineWt : 0);
          setDeductGold(0);
          // setPaymentAmount(totalAmount);
        } else {
          fineWt = parseFloat(
            (metalPaymentOption.totalAmount * 10) / value
          ).toFixed(3);
          setMetalPaymentOption({
            ...metalPaymentOption,
            fineWt: fineWt !== "" ? fineWt : 0,
            fineRate: value,
            deductGold: 0,
            deductSilver: fineWt !== "" ? fineWt : 0,
          });
          // setPaymentAmount(totalAmount);
          setDeductSilver(fineWt !== "" ? fineWt : 0);
          setPaymentSilver(fineWt !== "" ? fineWt : 0);
          setDeductGold(0);
        }
      }
    }
  };
  console.log(allSelectedProducts, "allSelectedProducts");
  console.log(purchaseProduct, "purchaseProduct");
  // console.log(metalPaymentOption, "metalPaymentOption");
  // console.log(metalPaymentOption, "metalPaymentOption");
  // console.log(purchaseProductList, "purchaseProductList");
  // console.log(selectedCustomer, "selectedCustomer");
  // console.log(selectedProduct);
  // console.log(openEditProduct, "openEditProduct");
  // console.log(paymentsString, "paymentsString");
  const filteredProducts = allProductTypes.filter(
    (product) => product.category_id == purchaseProduct.CategoryId
  );
  const filteredPurities = allPurities.filter((product) => {
    if (purchaseProduct.CategoryName === "Old Gold") {
      return product.category == "Gold";
    } else {
      return product.category == purchaseProduct.CategoryName;
    }
  });

  const filteredProductsUnlabel = allProductTypes.filter(
    (product) => product.category_id == unlabelProduct.category_id
  );
  const filteredPuritiesUnlabel = allPurities.filter((product) => {
    if (unlabelProduct.CategoryName === "Old Gold") {
      return product.category == "Gold";
    } else {
      return product.category == unlabelProduct.CategoryName;
    }
  });
  const filteredUnlabelCollection = allUnlabelList.filter(
    (product) => product.productTypeId == unlabelProduct.productTypeId
  );

  const button1Ref = useRef(null);
  const button2Ref = useRef(null);
  const button3Ref = useRef(null);
  const button4Ref = useRef(null);
  const button5Ref = useRef(null);
  const button6Ref = useRef(null);
  const button7Ref = useRef(null);
  const button8Ref = useRef(null);
  const button9Ref = useRef(null);
  const button10Ref = useRef(null);
  const button11Ref = useRef(null);
  const button12Ref = useRef(null);
  let totalPaidCashAmount = 0;
  useEffect(() => {
    totalPaidCashAmount = payments
      .filter((x) => x.mode == "Cash")
      .reduce((a, b) => parseInt(a) + parseInt(b.amount), 0);
  }, [payments, paymentAmount, paymentOptions]);

  return (
    <div>
      <AdminHeading />

      <div className="adminMainBodyBox">
        {/* <AdminBreadCrump
            title={"New Invoice"}
            companyName={"Loyalstring"}
            module={"Trading"}
            page={"Invoice"}
          /> */}
        <div className="adminAddCategoryMainBox">
          <div
            style={{ marginBottom: "50px", paddingTop: "0px" }}
            className="adminAddCategoryInnerBox"
          >
            <div className="invoiceFormDateTimeBox">
              <DateTime
                dateRcvd={selectedDate ? selectedDate : null}
                showInv={false}
              />
              <div className="invoiceFormDateTimeSelectDateBox">
                <input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
            </div>
            {/* <h4 className="adminInvoiceAddTitles">Add Customer</h4> */}
            <div
              style={{ marginBottom: "0px" }}
              id="adminInvoiceAddCustomerTitle"
              className="adminInvoiceSelectLabelBox"
            >
              <div className="adminInvoiceSelectItem">
                {/* <button >Check</button> */}
                <label>Firm Name</label>
                <input
                  style={{ width: "20vw" }}
                  type="text"
                  name="customerName"
                  value={customerName}
                  onInput={handleNameInputChange}
                  list="customerNamesList"
                />
                <datalist id="customerNamesList">
                  {allCsData.map((customer, index) => (
                    <option key={index} value={`${customer.firm_name}`} />
                  ))}
                </datalist>
                <button
                  onClick={() => {
                    //   setSelectedCustomer(null),
                    // setAddNewCustomer(!addNewCustomer),
                    // checkIfNewCs();

                    navigate("/add_supplier");
                  }}
                  className="adminInvoiceAddCustomerOption"
                >
                  <AiOutlinePlusSquare size={"20px"} />
                </button>
                {selectedCustomer ? (
                  <div className="adminInvoiceAddedCustomerEditIconBox">
                    <button
                      onClick={() => {
                        setSelectedCustomerEdit(!selectedCustomerEdit),
                          // scrollToCenter("adminInvoiceAddedCustomerEdit");
                          scrollToCenter(
                            "adminInvoiceAddProductsOptionsTypeBox"
                          );
                      }}
                    >
                      <AiOutlineEdit size={"20px"} />
                    </button>
                    <button
                      onClick={() => {
                        setSelectedCustomer(null);
                        scrollToCenter("adminInvoiceAddCustomerTitle");
                        // scrollToCenter("adminInvoiceAddProductsOptionsTypeBox");
                      }}
                      id="adminInvoiceAddedCustomerRemoveIcon"
                    >
                      <RiDeleteBin2Line size={"20px"} />
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="adminInvoiceSelectItem">
                <label>Inward Number</label>
                <input
                  type="text"
                  readOnly
                  value={
                    selectedCustomer
                      ? parseInt(selectedCustomer.inwardNo) + 1
                      : 0
                  }
                />
              </div>
              <div className="adminInvoiceSelectItem">
                <label>Invoice Number</label>
                <input
                  type="text"
                  value={invoiceNumber}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      setInvoiceNumber(e.target.value);
                      setGstType(true);
                    } else {
                      setInvoiceNumber(e.target.value);
                      setGstType(false);
                    }
                  }}
                />
              </div>
            </div>
            <div
              style={{ marginTop: "0px" }}
              className="adminInvoiceSelectLabelBox"
            >
              <div className="adminInvoiceSelectItem">
                <label>Fine Gold : </label>
                {/* <input
                  type="text"
                  readOnly
                  value={selectedCustomer ? selectedCustomer.fineGold : 0}
                /> */}
                <h4 className="adminInvoiceSelectItemBalanceMetal">
                  {selectedCustomer ? selectedCustomer.fineGold : 0}
                </h4>
              </div>
              <div className="adminInvoiceSelectItem">
                <label>Fine Silver : </label>
                {/* <input
                  type="text"
                  readOnly
                  value={selectedCustomer ? selectedCustomer.fineSilver : 0}
                /> */}
                <h4 className="adminInvoiceSelectItemBalanceMetal">
                  {selectedCustomer ? selectedCustomer.fineSilver : 0}
                </h4>
              </div>
              <div className="adminInvoiceSelectItem">
                <label>Advance Amount : </label>
                {/* <input
                  type="text"
                  readOnly
                  value={selectedCustomer ? selectedCustomer.advanceAmt : 0}
                  /> */}
                <h4>{selectedCustomer ? selectedCustomer.advanceAmt : 0}</h4>
              </div>
              <div className="adminInvoiceSelectItem">
                <label>Balance Amount : </label>
                {/* <input
                  type="text"
                  readOnly
                  value={selectedCustomer ? selectedCustomer.balanceAmt : 0}
                  /> */}
                <h4>{selectedCustomer ? selectedCustomer.balanceAmt : 0}</h4>
              </div>
            </div>

            {/* <div className="adminInvoiceSelectItem">
                <label>Customer Mobile</label>
                <input
                  type="text"
                  name="customerMobile"
                  value={customerMobile}
                  onInput={handleMobileInputChange}
                  list="customerMobilesList"
                />
                <datalist id="customerMobilesList">
                  {allCsData.map((customer) => (
                    <option key={customer.id} value={customer.mobile} />
                  ))}
                </datalist>
              </div> */}
            {/* <div className="adminInvoiceSelectItem">
                  <label>Customer Email</label>
                  <input
                    type="text"
                    name="customerEmails"
                    value={customerEmail}
                    onInput={handleEmailInputChange}
                    list="customerEmailsList"
                  />
                  <datalist id="customerEmailsList">
                    {allCsData.map((customer) => (
                      <option key={customer.id} value={customer.email} />
                    ))}
                  </datalist>
                </div> */}
            {/* <div className="adminInvoiceSelectItem">
                <label>Customer Address</label>
                <input
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      checkIfNewCs();
                    }
                  }}
                  type="text"
                  name="customerAddress"
                  value={customerAddress}
                  onInput={handleAddressInputChange}
                  list="customersAddressList"
                />
                <datalist id="customersAddressList">
                  {allCsData.map((customer) => (
                    <option key={customer.id} value={customer.currAddStreet} />
                  ))}
                </datalist>
              </div> */}

            {selectedCustomer &&
            !selectedCustomerEdit ? null : selectedCustomer && // </div> //   </div> */} //     </button> //       <RiDeleteBin2Line size={"20px"} /> //     > //       id="adminInvoiceAddedCustomerRemoveIcon" //       }} //         scrollToCenter("adminInvoiceAddCustomerTitle"); //         setSelectedCustomer(null); //       onClick={() => { //     <button //     </button> //       <AiOutlineEdit size={"20px"} /> //     > //       }} //           scrollToCenter("adminInvoiceAddedCustomerEdit"); //         setSelectedCustomerEdit(!selectedCustomerEdit), //       onClick={() => { //     <button //   {/* <div className="adminInvoiceAddedCustomerEditIconBox"> //   </div> //     <p>CGST No.: {selectedCustomer.central_gst_no}</p> */} //     <p>GST No.: {selectedCustomer.gst_no}</p> //     {/* <p>Pan No.: {selectedCustomer.party_pan_no}</p> //     </p> //       {selectedCustomer.state} //       Add: {selectedCustomer.address} {selectedCustomer.city}{" "} //     <p> //     <p>{selectedCustomer.supplierType}</p> //     <p>{selectedCustomer.email_id}</p> //     <p>{selectedCustomer.contact_no}</p> //     <p>{selectedCustomer.supplier_name}</p> //   <div> // <div className="adminInvoiceAddedCustomerBox">
              selectedCustomerEdit ? (
              <div className="adminInvoiceAddedCustomerEditMainBox">
                <p>Personal Details</p>
                <div className="adminInvoiceAddedCustomerEditBox">
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Supplier Code</label>
                    <input
                      readOnly
                      value={selectedCustomer.supplier_code}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Supplier Name</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "supplier_name")
                      }
                      value={selectedCustomer.supplier_name}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Supplier Type</label>
                    <select
                      onChange={(e) =>
                        handleCustomerInputChange(e, "supplierType")
                      }
                      value={selectedCustomer.supplierType}
                    >
                      <option value={"Party"}>Party</option>
                      <option value={"Karigar"}>Karigar</option>{" "}
                    </select>
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Firm Name</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "firm_name")
                      }
                      value={selectedCustomer.firm_name}
                      type="text"
                    />
                  </div>
                  {/* <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Firm Details</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "firm_details")
                      }
                      value={selectedCustomer.firm_details}
                      type="text"
                    />
                  </div> */}

                  {/* <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Last Name</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "lastName")}
                      value={selectedCustomer.lastName}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Mobile</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "mobile")}
                      value={selectedCustomer.mobile}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Email</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "email")}
                      value={selectedCustomer.email}
                      type="text"
                    />
                  </div> */}
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Aadhar No.</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "party_adhar_no")
                      }
                      value={selectedCustomer.party_adhar_no}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Pan No.</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "party_pan_no")
                      }
                      value={selectedCustomer.party_pan_no}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>GSTIN No.</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "gst_no")}
                      value={selectedCustomer.gst_no}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Central GST No.</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "central_gst_no")
                      }
                      value={selectedCustomer.central_gst_no}
                      type="text"
                    />
                  </div>
                </div>
                <p>Contact Information</p>
                <div className="adminInvoiceAddedCustomerEditBox">
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Contact No</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "contact_no")
                      }
                      value={selectedCustomer.contact_no}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Email Id</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "email_id")}
                      value={selectedCustomer.email_id}
                      type="text"
                    />
                  </div>

                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Address</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "address")}
                      value={selectedCustomer.address}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>City</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "city")}
                      value={selectedCustomer.city}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>State</label>
                    <input
                      onChange={(e) => handleCustomerInputChange(e, "state")}
                      value={selectedCustomer.state}
                      type="text"
                    />
                  </div>
                </div>
                {/* <p>Per Add</p>
                <div className="adminInvoiceAddedCustomerEditBox">
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Street</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddStreet")
                      }
                      value={selectedCustomer.perAddStreet}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Town</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddTown")
                      }
                      value={selectedCustomer.perAddTown}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>State</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddState")
                      }
                      value={selectedCustomer.perAddState}
                      type="text"
                    />
                  </div>
                  <div className="adminInvoiceAddedCustomerEditItems">
                    <label>Pincode</label>
                    <input
                      onChange={(e) =>
                        handleCustomerInputChange(e, "perAddPinCode")
                      }
                      value={selectedCustomer.perAddPinCode}
                      type="text"
                    />
                  </div>
                </div> */}
                <div className="adminInvoiceAddedCustomerEditButtonBox">
                  <button onClick={() => updateCustomerDetails()}>Save</button>
                  <button
                    onClick={() => {
                      scrollToCenter("adminInvoiceAddCustomerTitle"),
                        setSelectedCustomerEdit(!selectedCustomerEdit);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : null}

            <h4
              id="adminInvoiceAddedCustomerEdit"
              className="adminInvoiceAddTitles"
            >
              Add Product
            </h4>

            <div className="adminInvoiceAddProductsOptionsTypeBox">
              <div className="adminAddCategoryInnerBoxTitlesBox">
                {/* <button
                  tabIndex="1"
                  onClick={() => {
                    setActive("Sell");
                  }}
                  style={{ height: "40px" }}
                  className={
                    active === "Sell"
                      ? "adminAddCategoryInnerBoxTitle"
                      : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                  }
                >
                  <div
                    className={
                      active === "Sell"
                        ? "adminAddCategoryInnerBoxTitleLogo"
                        : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                    }
                    style={{
                      height: "20px",
                      width: "20px",
                      padding: "3px",
                      marginInline: "3px",
                    }}
                  >
                    <AiOutlineSend size={"15px"} />
                  </div>
                  <p style={{ fontSize: "12px" }}>Sell</p>
                </button> */}

                <button
                  onClick={() => setActive("Purchase")}
                  style={{ height: "40px" }}
                  className={
                    active === "Purchase"
                      ? "adminAddCategoryInnerBoxTitle"
                      : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                  }
                >
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      padding: "3px",
                      marginInline: "3px",
                    }}
                    className={
                      active === "Purchase"
                        ? "adminAddCategoryInnerBoxTitleLogo"
                        : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                    }
                  >
                    {/* 02 */}

                    <LiaCartPlusSolid size={"30px"} />
                  </div>
                  <p style={{ fontSize: "12px" }}>Purchase</p>
                </button>
                <button
                  onClick={() => setActive("P Accounting")}
                  style={{ height: "40px" }}
                  className={
                    active === "P Accounting"
                      ? "adminAddCategoryInnerBoxTitle"
                      : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                  }
                >
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      padding: "3px",
                      marginInline: "3px",
                    }}
                    className={
                      active === "P Accounting"
                        ? "adminAddCategoryInnerBoxTitleLogo"
                        : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                    }
                  >
                    {/* 02 */}

                    <MdOutlineLabelOff size={"17px"} />
                  </div>
                  <p style={{ fontSize: "12px" }}>P Accounting</p>
                </button>
                <div className="bulkStockAddProductDetailsItem">
                  <label style={{ margin: 0, cursor: "pointer" }}>
                    {/* Images {`${selectedFiles.length}`} */}
                    <BsImages
                      className="bulkStockAddProductAddImagesIcon"
                      style={{ margin: "1.2rem", marginInline: "1rem" }}
                      size={"2.5rem"}
                    />
                    <input
                      id="images"
                      style={{ display: "none" }}
                      type="file"
                      multiple
                      onChange={handleFileInputChange}
                    />
                  </label>
                  <label> Images {`${selectedFiles.length}`}</label>
                </div>
                {/* <div className="bulkStockAddProductDetailsItem">
                  <label>Select Date</label>
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div> */}
              </div>
            </div>
            {active === "Sell" ? (
              <div className="adminInvoiceAddProductsOptionsMainSellBox">
                {!productsLoading ? (
                  <div className="adminInvoiceAddProductsOptionsMainBox">
                    <div
                      id="adminInvoiceAddProductsOptionsInnerBox"
                      className="adminInvoiceAddProductsOptionsInnerBox"
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>ITEM DETAILS</th>
                            <th>RATE</th>
                            <th>GROSS WT</th>
                            <th>NET WT</th>
                            <th>PURITY</th>
                            <th>MAKING</th>
                            <th>PRICE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allSelectedProducts.length > 0
                            ? allSelectedProducts.map((x, index) => (
                                <tr
                                  style={{
                                    borderBottom:
                                      "1px solid  rgba(128, 128, 128, 0.3)",
                                  }}
                                >
                                  <td>
                                    <div className="adminAddInvoiceMainAddLabelOption">
                                      <div className="adminAddInvoiceMainAddLabelOptionImageBox">
                                        <BsCardImage size={"30px"} />
                                      </div>
                                      <div className="adminAddInvoiceMainAddLabelOptionLabelBox">
                                        {x.purchase ? (
                                          <p
                                            style={{
                                              textAlign: "left",
                                              margin: "5px",
                                              padding: "5px",
                                              marginBottom: "0px",
                                              paddingBottom: "0px",
                                              color: "red",
                                            }}
                                          >
                                            Purchase
                                          </p>
                                        ) : x.unlabel ? (
                                          <p
                                            style={{
                                              textAlign: "left",
                                              margin: "5px",
                                              padding: "5px",
                                              marginBottom: "0px",
                                              paddingBottom: "0px",
                                              color: "green",
                                            }}
                                          >
                                            Unlabel
                                          </p>
                                        ) : (
                                          <p
                                            style={{
                                              textAlign: "left",
                                              margin: "5px",
                                              padding: "5px",
                                              marginBottom: "0px",
                                              paddingBottom: "0px",
                                            }}
                                          >
                                            {x.itemCode}
                                          </p>
                                        )}
                                        {x.purchase ? (
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              color: "red",
                                              fontSize: "10px",
                                              textAlign: "left",
                                              margin: "0px 5px",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {`${x.CategoryName}, ${x.ProductName}`}
                                          </p>
                                        ) : x.unlabel ? (
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              color: "green",
                                              fontSize: "10px",
                                              textAlign: "left",
                                              margin: "0px 5px",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {`${x.CategoryName}, ${x.ProductName}, ${x.collectionName}`}
                                          </p>
                                        ) : (
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              color: "#02a8b5",
                                              fontSize: "10px",
                                              textAlign: "left",
                                              margin: "0px 5px",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {`${x.category_Name}, ${x.itemType}, ${x.collection}`}
                                          </p>
                                        )}
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            color: "#02a8b5",
                                            fontSize: "10px",
                                            textAlign: "left",
                                            margin: "0px 5px",
                                            padding: "0px 5px",
                                          }}
                                        ></p>
                                      </div>
                                      <div className="adminAddInvoiceMainAddLabelOptionEditIconBox">
                                        <button
                                          onClick={() => {
                                            editItem(x);
                                          }}
                                          className="adminAddInvoiceMainAddLabelOptionEditIcon"
                                        >
                                          <AiOutlineEdit />
                                        </button>
                                        <button
                                          style={{ marginBottom: "5px" }}
                                          onClick={() => {
                                            x.purchase
                                              ? removePurchaseProductFromList(
                                                  index
                                                )
                                              : x.unlabel
                                              ? removePurchaseProductFromList(
                                                  index
                                                )
                                              : removeProductFromList(x.id);
                                          }}
                                          className="adminAddInvoiceMainAddLabelOptionDeleteIcon"
                                        >
                                          <RxCross2 />
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  {x.purchase ? (
                                    <td> ₹{parseFloat(x.Rate).toFixed(0)}</td>
                                  ) : x.unlabel ? (
                                    <td>
                                      {" "}
                                      ₹{parseFloat(x.GoldRate).toFixed(0)}
                                    </td>
                                  ) : (
                                    <td>
                                      {/* <input
                                          type="number"
                                          onChange={(e) =>
                                            handleInputChange2(e, "todaysRate")
                                          }
                                          value={parseFloat(
                                            x.tblPurity.todaysRate
                                          ).toFixed(0)}
                                        /> */}
                                      ₹
                                      {parseFloat(
                                        x.tblPurity.todaysRate
                                      ).toFixed(0)}
                                    </td>
                                  )}{" "}
                                  {x.purchase ? (
                                    <td>{parseFloat(x.GrossWt).toFixed(3)}</td>
                                  ) : x.unlabel ? (
                                    <td>{parseFloat(x.grosswt).toFixed(3)}</td>
                                  ) : (
                                    <td>{parseFloat(x.grosswt).toFixed(3)}</td>
                                  )}
                                  {x.purchase ? (
                                    <td> {parseFloat(x.NetWt).toFixed(3)}</td>
                                  ) : x.unlabel ? (
                                    <td> {parseFloat(x.netwt).toFixed(3)}</td>
                                  ) : (
                                    <td> {parseFloat(x.netWt).toFixed(3)}</td>
                                  )}
                                  {x.purchase ? (
                                    <td> {parseFloat(x.Purity).toFixed(3)}</td>
                                  ) : x.unlabel ? (
                                    <td> {parseFloat(x.purity).toFixed(3)}</td>
                                  ) : (
                                    <td> {parseFloat(x.purity).toFixed(3)}</td>
                                  )}
                                  <td> ₹{parseFloat(x.making).toFixed(3)}</td>
                                  {x.purchase ? (
                                    <td>
                                      ₹{parseFloat(x.PurchaseAmount).toFixed(3)}
                                    </td>
                                  ) : (
                                    <td>
                                      ₹
                                      {parseFloat(
                                        parseFloat(x.finalPrice) +
                                          parseFloat(x.totalGstAmount)
                                      ).toFixed(3)}
                                    </td>
                                  )}
                                </tr>
                              ))
                            : null}
                          <tr>
                            <td>
                              <div className="adminAddInvoiceMainAddLabelOption">
                                <div className="adminAddInvoiceMainAddLabelOptionImageBox">
                                  <BsCardImage size={"30px"} />
                                </div>
                                <div className="adminAddInvoiceMainAddLabelOptionLabelBox">
                                  <input
                                    // tabIndex="1"
                                    type="text"
                                    placeholder="Type or click to select an item"
                                    name="productLabel"
                                    value={labelName}
                                    onInput={handleProductLabelChange}
                                    onKeyPress={(e) => {
                                      if (e.key === "Ctrl") {
                                        e.preventDefault();
                                        // button1Ref.current.focus();
                                        alert("Space");
                                      } else if (e.key === "Enter") {
                                        // Call your function here
                                        if (selectedProduct.length !== 0) {
                                          calculateFinalPrice(
                                            selectedProduct,
                                            true
                                          );
                                        } else {
                                          // null;
                                          button1Ref.current.focus();
                                        }
                                      }
                                    }}
                                    list="productLabelList"
                                  />
                                  <datalist id="productLabelList">
                                    {allProducts.map((product, index) => (
                                      <option
                                        key={index}
                                        value={product.itemCode}
                                      />
                                    ))}
                                  </datalist>
                                </div>
                              </div>
                            </td>
                            {selectedProduct.length > 0 ? (
                              <td>{selectedProduct.tblPurity.todaysRate}</td>
                            ) : (
                              <td>0</td>
                            )}
                            <td>{selectedProduct.grosswt}</td>
                            <td> {selectedProduct.netWt}</td>
                            <td> {selectedProduct.purity}</td>
                            <td>{selectedProduct.making}</td>

                            <td>₹{Math.ceil(totalPayableAmount)} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <tr>
                    <td>
                      <div className="adminAddInvoiceMainAddLabelOption">
                        <div className="adminAddInvoiceMainAddLabelOptionImageBox">
                          <BsCardImage size={"30px"} />
                        </div>
                        <div className="adminAddInvoiceMainAddLabelOptionLabelBox">
                          <input
                            type="text"
                            placeholder="Type or click to select an item"
                            name="productLabel"
                            value={labelName}
                            onInput={handleProductLabelChange}
                            list="productLabelList"
                          />
                          <datalist id="productLabelList">
                            {allProducts.map((product, index) => (
                              <option key={index} value={product.itemCode} />
                            ))}
                          </datalist>
                        </div>
                      </div>
                    </td>
                    <td>{selectedProduct.grosswt}</td>
                    <td> {selectedProduct.netWt}</td>
                    <td> {selectedProduct.purity}</td>
                    <td> {selectedProduct.making}</td>

                    <td>
                      {parseFloat(
                        parseFloat(selectedProduct.finalPrice) +
                          parseFloat(selectedProduct.totalGstAmount)
                      ).toFixed(3)}
                    </td>
                  </tr>
                )}

                {openEditBox ? (
                  <div className="adminInvoiceOpenEditMainBox">
                    <div className="adminInvoiceOpenEditInnerBox">
                      <div className="adminInvoiceOpenEditInnerTitleBox">
                        <p>Edit Item</p>
                        <button
                          onClick={closeEditItem}
                          className="adminAddInvoiceMainAddLabelOptionDeleteIcon"
                        >
                          <RxCross2 size={"25px"} />
                        </button>
                      </div>
                      <div className="adminInvoiceOpenEditOuterGridBox">
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Gross Wt</label>
                          <input
                            type="text"
                            placeholder={openEditProduct.grosswt}
                            value={openEditProduct.grosswt}
                            onChange={(e) => handleInputChange2(e, "grosswt")}
                          />
                        </div>

                        {openEditProduct.purchase || openEditProduct.unlabel ? (
                          <div className="adminInvoiceOpenEditInnerGridItem">
                            <label>Stone Wt</label>{" "}
                            <input
                              type="number"
                              placeholder={openEditProduct.stonewt}
                              value={openEditProduct.stonewt}
                              onChange={(e) => handleInputChange2(e, "stonewt")}
                            />
                          </div>
                        ) : (
                          <div className="adminInvoiceOpenEditInnerGridItem">
                            <label>Stone Wt</label>{" "}
                            <input
                              type="number"
                              placeholder={openEditProduct.stoneWeight}
                              value={openEditProduct.stoneWeight}
                              onChange={(e) =>
                                handleInputChange2(e, "stoneWeight")
                              }
                            />
                          </div>
                        )}
                        {openEditProduct.purchase || openEditProduct.unlabel ? (
                          <div className="adminInvoiceOpenEditInnerGridItem">
                            <label>Net Wt</label>
                            <input
                              type="text"
                              placeholder={openEditProduct.netwt}
                              value={openEditProduct.netwt}
                              onChange={(e) => handleInputChange2(e, "netwt")}
                            />
                          </div>
                        ) : (
                          <div className="adminInvoiceOpenEditInnerGridItem">
                            <label>Net Wt</label>
                            <input
                              type="text"
                              placeholder={openEditProduct.netWt}
                              value={openEditProduct.netWt}
                              onChange={(e) => handleInputChange2(e, "netWt")}
                            />
                          </div>
                        )}
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Stone Amount</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.stoneAmount}
                            value={openEditProduct.stoneAmount}
                            onChange={(e) =>
                              handleInputChange2(e, "stoneAmount")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Product Name</label>
                          <input
                            type="text"
                            placeholder={openEditProduct.product_Name}
                            value={openEditProduct.product_Name}
                            onChange={(e) =>
                              handleInputChange2(e, "product_Name")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>HUID Code</label>
                          <input
                            type="text"
                            maxLength={6}
                            placeholder={openEditProduct.huidCode}
                            value={openEditProduct.huidCode}
                            onChange={(e) => handleInputChange2(e, "huidCode")}
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making PerGram</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.making_per_gram}
                            value={openEditProduct.making_per_gram}
                            onChange={(e) =>
                              handleInputChange2(e, "making_per_gram")
                            }
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making Percentage</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.making_Percentage}
                            value={openEditProduct.making_Percentage}
                            onChange={(e) =>
                              handleInputChange2(e, "making_Percentage")
                            }
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making Fixed Amount</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.making_Fixed_Amt}
                            value={openEditProduct.making_Fixed_Amt}
                            onChange={(e) =>
                              handleInputChange2(e, "making_Fixed_Amt")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Making Fixed Wastage</label>
                          <input
                            type="number"
                            placeholder={openEditProduct.making_Fixed_Wastage}
                            value={openEditProduct.making_Fixed_Wastage}
                            onChange={(e) =>
                              handleInputChange2(e, "making_Fixed_Wastage")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Pieces</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.pieces}
                            value={openEditProduct.pieces}
                            onChange={(e) => handleInputChange2(e, "pieces")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Size</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.size}
                            value={openEditProduct.size}
                            onChange={(e) => handleInputChange2(e, "size")}
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>MRP</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.mrp}
                            value={openEditProduct.mrp}
                            onChange={(e) => handleInputChange2(e, "mrp")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Description</label>{" "}
                          <input
                            type="text"
                            placeholder={openEditProduct.description}
                            value={openEditProduct.description}
                            onChange={(e) =>
                              handleInputChange2(e, "description")
                            }
                          />
                        </div>
                        {/* <div className="adminInvoiceOpenEditInnerGridItem">
                            <label>Occasion</label>{" "}
                            <input
                              type="text"
                              placeholder={openEditProduct.occasion}
                              value={openEditProduct.occasion}
                              onChange={(e) => handleInputChange2(e, "occasion")}
                            />
                          </div> */}
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Todays Rate</label>{" "}
                          <input
                            type="text"
                            placeholder={openEditProduct.tblPurity.todaysRate}
                            value={openEditProduct.tblPurity.todaysRate}
                            onChange={(e) =>
                              handleInputChange2(e, "todaysRate")
                            }
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Hallmark Amount</label>{" "}
                          <input
                            type="number"
                            placeholder={openEditProduct.hallmark_amt}
                            value={openEditProduct.hallmark_amt}
                            onChange={(e) =>
                              handleInputChange2(e, "hallmark_amt")
                            }
                          />
                        </div>

                        <div className="adminInvoiceOpenEditInnerGridItem">
                          <label>Featured</label>{" "}
                          <input
                            type="text"
                            placeholder={openEditProduct.featured}
                            value={openEditProduct.featured}
                            onChange={(e) => handleInputChange2(e, "featured")}
                          />
                        </div>
                        <div className="adminInvoiceOpenEditInnerGridItem">
                          {/* <label>Update</label>{" "} */}.{" "}
                          <button
                            onClick={() => {
                              setOpenEditBox(false),
                                setSelectedProduct([]),
                                setLabelName("");
                            }}
                            className="adminInvoiceEditProductSaveButton"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : active === "Purchase" ? (
              <div className="adminPurchaseInvoiceAddProductsOptionsMainPurchaseBox">
                <div className="adminPurchaseInvoiceAddProductsOptionsMainPurchaseItems">
                  {/* <div className="adminInvoiceAddProductsOptionsMainBox">
             <div
               id="adminInvoiceAddProductsOptionsInnerBox"
               className="adminInvoiceAddProductsOptionsInnerBox"
             > */}
                  <table>
                    <thead>
                      <tr>
                        <div>
                          <th>SKU</th>
                          <input
                            // style={{ width: "30vw" }}
                            type="text"
                            name="skuList"
                            placeholder="Enter SKU"
                            value={selectedSkuName}
                            onInput={handleSkuInputChange}
                            list="skuList"
                          />
                          <datalist id="skuList">
                            {allSkuList.map((sku, index) => (
                              <option
                                key={index}
                                value={`${sku.stockKeepingUnit}`}
                              />
                            ))}
                          </datalist>
                        </div>
                        <div>
                          <th>CATEGORY</th>
                          <select
                            name="CategoryId"
                            // onChange={handleInputChangePurchase}
                            onChange={(e) => {
                              setSelectedCategory(e.target.value),
                                handleInputChangePurchase(e);
                            }}
                            // value={purchaseProduct.names}
                            value={selectedCategory}
                            // value={purchaseProduct.CategoryName}
                          >
                            <option value={""}>Select an Category</option>
                            {allCategories.map((x, y) => {
                              return (
                                <option key={y} value={`${x.id},${x.name}`}>
                                  {x.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div>
                          <th>PRODUCT</th>
                          <select
                            name="ProductName"
                            onChange={(e) => {
                              setSelectedProductType(e.target.value),
                                handleInputChangePurchase(e);
                            }}
                            // value={purchaseProduct.ProductNames}
                            // value={purchaseProduct.ProductName}
                            value={selectedProductType}
                          >
                            <option value={""}>Select an Product</option>
                            {filteredProducts.map((x) => {
                              return (
                                <option value={`${x.id},${x.productTitle}`}>
                                  {x.productTitle}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div>
                          <th>GROSS WT</th>
                          <input
                            name="GrossWt"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.GrossWt}
                          />
                        </div>
                        <div>
                          <th>STONE WT</th>
                          <input
                            name="StoneWt"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.StoneWt}
                          />
                        </div>
                        <div>
                          <th>NET WT</th>
                          <input
                            name="NetWt"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.NetWt}
                          />
                        </div>
                        <div>
                          <th>PURITY</th>
                          <input
                            name="Purity"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.Purity}
                          />
                        </div>
                        <div>
                          <th>WASTAGE</th>
                          <input
                            name="Wastage"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.Wastage}
                          />
                        </div>
                        <div>
                          <th>RATE/10GM</th>
                          <div className="adminPurchaseEntryDollarSignBox">
                            <FaDollarSign
                              className="adminPurchaseEntryDollarSign"
                              onClick={() => setConvertAmount(!convertAmount)}
                              size={"15px"}
                              style={{
                                cursor: "pointer",
                                color: convertAmount ? "green" : "grey",
                              }}
                            />
                            <input
                              name="Rate"
                              onChange={handleInputChangePurchase}
                              type="text"
                              value={purchaseProduct.Rate}
                            />
                          </div>
                        </div>
                        <div>
                          <th>QUANTITY</th>
                          <input
                            name="Quantity"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.Quantity}
                          />
                        </div>

                        <div>
                          <th>MAKING %</th>
                          <input
                            name="MakingPercentage"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.MakingPercentage}
                          />
                        </div>

                        <div>
                          <th>MAKING/GM</th>
                          <input
                            name="MakingPerGram"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.MakingPerGram}
                          />
                        </div>

                        <div>
                          <th>FIXED MAKING</th>
                          <input
                            name="MakingFixedAmt"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.MakingFixedAmt}
                          />
                        </div>

                        <div>
                          <th>STONE AMOUNT</th>
                          <input
                            name="StoneAmt"
                            onChange={handleInputChangePurchase}
                            type="text"
                            value={purchaseProduct.StoneAmt}
                          />
                        </div>

                        <div>
                          <th>TOTAL ITEM AMT</th>
                          <input
                            name="TotalItemAmt"
                            // onChange={handleInputChangePurchase}
                            readOnly
                            type="text"
                            value={parseFloat(
                              purchaseProduct.TotalItemAmt
                            ).toFixed(3)}
                          />
                        </div>

                        <div>
                          <th>FINE WT</th>
                          <input
                            name="FineWt"
                            // onChange={handleInputChangePurchase}
                            type="text"
                            readOnly
                            value={parseFloat(purchaseProduct.FineWt).toFixed(
                              3
                            )}
                          />
                        </div>

                        <div>
                          <th>F WT + W WT</th>
                          <input
                            name="FineWastageWt"
                            readOnly
                            // onChange={handleInputChangePurchase}
                            type="text"
                            value={parseFloat(
                              purchaseProduct.FineWastageWt
                            ).toFixed(3)}
                          />
                        </div>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  {/* <label>Product</label>

                  <select
                    name="productname"
                    onChange={handleInputChangePurchase}
                    value={purchaseProduct.productname}
                  >
                    <option value={""}>Select an Product</option>
                    {filteredProducts.map((x) => {
                      return (
                        <option value={x.productTitle}>{x.productTitle}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Purity</label>

                  <select
                    name="purityRate"
                    onChange={handleInputChangePurchase}
                    value={purchaseProduct.purities}
                  >
                    <option>Select an Purity</option>
                    {filteredPurities.map((x) => {
                      return (
                        <option value={`${x.purity},${x.todaysRate}`}>
                          {x.purity}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Gross Wt</label>
                  <input
                    name="grosswt"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.grosswt}
                  />
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Net Wt</label>
                  <input
                    name="netwt"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.netwt}
                  />
                </div>
             
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Gold Rate</label>
                  <input
                    name="GoldRate"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.GoldRate}
                  />
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Fine Percentage</label>
                  <input
                    name="Finepercent"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.Finepercent}
                  />
                </div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Quantity</label>
                  <input
                    name="Quantity"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.Quantity}
                  />
                </div>

                <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                  <label>Purchase Amount</label>
                  <input
                    name="PurchaseAmount"
                    onChange={handleInputChangePurchase}
                    type="text"
                    value={purchaseProduct.PurchaseAmount}
                  /> */}
                </div>
                <div
                  style={{ justifyContent: "flex-start", margin: "20px 0px" }}
                  className="adminInvoiceAddProductsOptionsMainPurchaseItems"
                >
                  {/* <label>Add</label>  */}
                  {/* <div style={{justifyContent:"flex-start"}}> */}

                  <button
                    onClick={() => addPurchaseProductToList(purchaseProduct)}
                  >
                    Add
                  </button>
                  <button
                    style={{ marginLeft: "20px" }}
                    onClick={() => setActive("Sell")}
                  >
                    Items
                  </button>
                  {/* </div> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="adminInvoiceAddProductsOptionsMainPurchaseBox">
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Category</label>
                    <select
                      name="category_id"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.names}
                    >
                      <option value={""}>Select an Category</option>
                      {allCategories.map((x) => {
                        return (
                          <option value={`${x.id},${x.name}`}>{x.name}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Product</label>

                    <select
                      name="productTypeId"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.productTypeNames}
                    >
                      <option value={""}>Select an Product</option>
                      {filteredProductsUnlabel.map((x) => {
                        return (
                          <option value={`${x.id},${x.productTitle}`}>
                            {x.productTitle}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Collection</label>

                    <select
                      name="collectionName"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.collectionNames}
                    >
                      <option value={""}>Select an Product</option>
                      {filteredUnlabelCollection.map((x) => {
                        return (
                          <option
                            value={`${x.id},${x.collection},${x.collectionId}`}
                          >
                            {x.collection}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Purity</label>

                    <select
                      name="purityRate"
                      onChange={handleInputChangeUnlabel}
                      value={unlabelProduct.purities}
                    >
                      <option>Select an Purity</option>
                      {filteredPuritiesUnlabel.map((x) => {
                        return (
                          <option value={`${x.purity},${x.todaysRate}`}>
                            {x.purity}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Gross Wt</label>
                    <input
                      name="grosswt"
                      onChange={handleInputChangeUnlabel}
                      // type="text"
                      value={unlabelProduct.grosswt}
                      type="number"
                      max={unlabelProduct.MaxGrossWt}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Net Wt</label>
                    <input
                      name="netwt"
                      onChange={handleInputChangeUnlabel}
                      type="number"
                      max={unlabelProduct.MaxNetWt}
                      value={unlabelProduct.netwt}
                    />
                  </div>
                  {/* <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Stone Wt</label>
                    <input
                      name="stonewt"
                      onChange={handleInputChangePurchase}
                      type="text"
                      value={purchaseProduct.stonewt}
                    />
                  </div> */}
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Gold Rate</label>
                    <input
                      name="GoldRate"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.GoldRate}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Fixed Amt</label>
                    <input
                      name="making_Fixed_Amt"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_Fixed_Amt}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Percentage</label>
                    <input
                      name="making_Percentage"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_Percentage}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Pergram</label>
                    <input
                      name="making_per_gram"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_per_gram}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Making Fixed Wastage</label>
                    <input
                      name="making_Fixed_Wastage"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.making_Fixed_Wastage}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Quantity</label>
                    <input
                      name="Quantity"
                      onChange={handleInputChangeUnlabel}
                      type="number"
                      min={0}
                      max={parseInt(unlabelProduct.MaxQuantity)}
                      value={unlabelProduct.Quantity}
                    />
                  </div>

                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    <label>Total Amount</label>
                    <input
                      name="unlabelAmount"
                      onChange={handleInputChangeUnlabel}
                      type="text"
                      value={unlabelProduct.unlabelAmount}
                    />
                  </div>
                  <div className="adminInvoiceAddProductsOptionsMainPurchaseItems">
                    {/* <label>Add</label>  */}
                    <button
                      onClick={() => addPurchaseProductToList(unlabelProduct)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
              className="adminInviceAddedProductsTotalOuterBox"
            >
              <div className="adminInviceAddedProductsTotalAmountOuterBox">
                <div className="adminInviceAddedProductsTotalItemBoxPaymentType">
                  <div
                    onClick={() => {
                      setPaymentType("Receive"),
                        setPaymentOptions("Cash"),
                        setPaymentAmount(Math.abs(paymentAmount));
                    }}
                  >
                    {paymentType === "Receive" ? (
                      <FaRegDotCircle style={{ marginRight: "5px" }} />
                    ) : (
                      <FaRegCircle style={{ marginRight: "5px" }} />
                    )}
                    Receive
                  </div>
                  <div onClick={() => setPaymentType("Paid")}>
                    {paymentType === "Paid" ? (
                      <FaRegDotCircle style={{ marginRight: "5px" }} />
                    ) : (
                      <FaRegCircle style={{ marginRight: "5px" }} />
                    )}
                    Paid
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    textAlign: "left",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                  className="adminInviceAddedProductsTotalItemBox"
                >
                  <label>Payment Mode</label>
                  <select
                    tabIndex="3"
                    ref={button2Ref}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        button3Ref.current.focus();
                      }
                    }}
                    style={{ width: "auto" }}
                    onChange={(e) => setPaymentOptions(e.target.value)}
                    value={paymentOptions}
                  >
                    <option value={"Cash"}>Cash</option>
                    <option value={"Card"}>Card</option>
                    <option value={"UPI"}>UPI</option>
                    <option value={"Cheque"}>Cheque</option>
                    <option value={"RTGS"}>RTGS</option>
                    <option value={"MDS"}>MDS</option>
                    {paymentType === "Paid" ? (
                      <>
                        <option value={"Advance Amount"}>Advance Amount</option>
                      </>
                    ) : null}
                    <option value={"Metal to Cash"}>Metal to Cash</option>
                    <option value={"Cash to Metal"}>Cash to Metal</option>
                  </select>
                  {paymentOptions !== "Advance Amount" ? (
                    <>
                      <label style={{ whiteSpace: "nowrap" }}>
                        Description
                      </label>
                      <input
                        style={{ width: "100%" }}
                        type="text"
                        value={paymentDescription}
                        onChange={(e) => setPaymentDescription(e.target.value)}
                      />
                      <label>Amount</label>
                      <div className="adminInviceAddedProductsAmountInputBox">
                        <input
                          style={{
                            color:
                              paymentType === "Paid" && paymentAmount !== 0
                                ? "red"
                                : paymentType === "Receive" && paymentAmount > 0
                                ? "green"
                                : "black",
                          }}
                          tabindex="4"
                          ref={button3Ref}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              button4Ref.current.focus();
                            }
                          }}
                          type="number"
                          value={paymentAmount}
                          onChange={(e) => setPaymentAmount(e.target.value)}
                        />
                        <button
                          tabindex="5"
                          ref={button4Ref}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              button5Ref.current.focus();
                            }
                          }}
                          onClick={() => {
                            if (
                              paymentOptions == "Cash" &&
                              totalPaidCashAmount + parseInt(paymentAmount) >
                                200000
                            ) {
                              alert("Could Not Take more than 200000 in Cash");
                            } else if (
                              paymentAmount > 200000 &&
                              paymentOptions == "Cash"
                            ) {
                              alert("Could'nt Take more than 200000 in Cash");
                            } else {
                              addPayment();
                            }
                          }}
                        >
                          <GiCheckMark />
                        </button>
                        <button
                          tabindex="6"
                          ref={button5Ref}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              button6Ref.current.focus();
                            }
                          }}
                          onClick={() => {
                            setPaymentAmount(0), setPaymentOptions("Cash");
                          }}
                        >
                          <RxCross2 />
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
                {paymentOptions === "Metal to Cash" ? (
                  <div className="adminInviceAddedProductsMetaltoCashMainBox">
                    <div>
                      <label>Metal</label>
                      <select
                        onChange={(e) =>
                          setMetalPaymentOption({
                            ...metalPaymentOption,
                            optionSelected: `${e.target.value}`,
                          })
                        }
                        value={metalPaymentOption.optionSelected}
                      >
                        <option value={"GOLD"}>GOLD</option>
                        <option value={"SILVER"}>SILVER</option>
                        <option value={"PLATINUM"}>PLATINUM</option>
                        <option value={"PURE GOLD"}>PURE GOLD</option>
                        <option value={"PURE SILVER"}>PURE SILVER</option>
                        <option value={"OLD GOLD"}>OLD GOLD</option>
                        <option value={"OLD SILVER"}>OLD SILVER</option>
                      </select>
                    </div>
                    <div>
                      <label>Fine Paid</label>
                      <input
                        type="number"
                        value={metalPaymentOption.fineWt}
                        onChange={(e) => {
                          handleMetalPaymentOption("fineWt", e);
                        }}
                        //     onChange={(e) =>
                        //       setMetalPaymentOption({
                        //         ...metalPaymentOption,
                        //         fineWt: e.target.value,
                        //     })
                        // }
                      />
                    </div>
                    <div>
                      <label>Rate 10/Gm</label>
                      <input
                        type="number"
                        value={metalPaymentOption.fineRate}
                        onChange={(e) => {
                          handleMetalPaymentOption("Rate", e);
                        }}
                        // onChange={(e) =>
                        //   setMetalPaymentOption({
                        //     ...metalPaymentOption,
                        //     fineRate: e.target.value,
                        //   })
                        // }
                      />
                    </div>
                    <div>
                      <label>Total amount</label>
                      <input
                        type="number"
                        value={metalPaymentOption.totalAmount}
                        readOnly
                      />
                    </div>
                    <div
                      style={{
                        margin: "10px",
                        width: "100px",
                        marginLeft: "auto",
                        marginRight: "0px",
                      }}
                      className="adminInvoiceMainSaveButtonBox"
                    >
                      <button onClick={addPayment}>Add</button>
                    </div>
                  </div>
                ) : paymentOptions === "Cash to Metal" ? (
                  <div className="adminInviceAddedProductsMetaltoCashMainBox">
                    <div>
                      <label>Metal</label>
                      <select
                        onChange={(e) =>
                          setMetalPaymentOption({
                            ...metalPaymentOption,
                            optionSelected: `${e.target.value}`,
                          })
                        }
                        value={metalPaymentOption.optionSelected}
                      >
                        <option value={"GOLD"}>GOLD</option>
                        <option value={"SILVER"}>SILVER</option>
                        <option value={"PLATINUM"}>PLATINUM</option>
                        <option value={"PURE GOLD"}>PURE GOLD</option>
                        <option value={"PURE SILVER"}>PURE SILVER</option>
                        <option value={"OLD GOLD"}>OLD GOLD</option>
                        <option value={"OLD SILVER"}>OLD SILVER</option>
                      </select>
                    </div>
                    <div>
                      <label>Total amount</label>
                      <input
                        type="number"
                        value={metalPaymentOption.totalAmount}
                        onChange={(e) => {
                          handleMetalPaymentOption("Amount", e);
                        }}
                      />
                    </div>
                    <div>
                      <label>Rate 10/Gm</label>
                      <input
                        type="number"
                        value={metalPaymentOption.fineRate}
                        onChange={(e) => {
                          handleMetalPaymentOption("Rate", e);
                        }}
                        // onChange={(e) =>
                        //   setMetalPaymentOption({
                        //     ...metalPaymentOption,
                        //     fineRate: e.target.value,
                        //   })
                        // }
                      />
                    </div>

                    <div>
                      <label>Fine Paid</label>
                      <input
                        type="number"
                        value={metalPaymentOption.fineWt}
                        readOnly
                        //     onChange={(e) =>
                        //       setMetalPaymentOption({
                        //         ...metalPaymentOption,
                        //         fineWt: e.target.value,
                        //     })
                        // }
                      />
                    </div>
                    <div
                      style={{
                        margin: "10px",
                        width: "100px",
                        marginLeft: "auto",
                        marginRight: "0px",
                      }}
                      className="adminInvoiceMainSaveButtonBox"
                    >
                      <button onClick={addPayment}>Add</button>
                    </div>
                  </div>
                ) : null}

                {paymentOptions === "Advance Amount" ? (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{ gridAutoFlow: "row" }}
                      className="adminInviceAddedProductsTotalItemBoxPaymentType"
                    >
                      <div
                        onClick={() => {
                          setPaymentAmount(Math.abs(paymentAmount));
                          setAdvanceType("Advance Received");
                        }}
                      >
                        {advanceType === "Advance Received" ? (
                          <FaRegDotCircle style={{ marginRight: "5px" }} />
                        ) : (
                          <FaRegCircle style={{ marginRight: "5px" }} />
                        )}
                        Adv Rcvd
                      </div>
                      <div onClick={() => setAdvanceType("Deduct Advance")}>
                        {advanceType === "Deduct Advance" ? (
                          <FaRegDotCircle style={{ marginRight: "5px" }} />
                        ) : (
                          <FaRegCircle style={{ marginRight: "5px" }} />
                        )}
                        Deduct Adv
                      </div>
                    </div>

                    {advanceType === "Advance Received" ? (
                      <div
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          textAlign: "left",
                        }}
                        className="adminInviceAddedProductsTotalItemBox"
                      >
                        <label style={{ whiteSpace: "nowrap" }}>
                          Description
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          value={paymentDescription}
                          onChange={(e) =>
                            setPaymentDescription(e.target.value)
                          }
                        />
                        <label>Amount</label>
                        <div className="adminInviceAddedProductsAmountInputBox">
                          <input
                            style={{
                              color:
                                paymentType === "Paid" && paymentAmount !== 0
                                  ? "red"
                                  : paymentType === "Receive" &&
                                    paymentAmount > 0
                                  ? "green"
                                  : "black",
                            }}
                            tabindex="4"
                            ref={button3Ref}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                button4Ref.current.focus();
                              }
                            }}
                            type="number"
                            value={advanceAmount}
                            onChange={(e) => setAdvanceAmount(e.target.value)}
                          />
                          <button
                            tabindex="5"
                            ref={button4Ref}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                button5Ref.current.focus();
                              }
                            }}
                            onClick={() => {
                              if (
                                paymentOptions == "Cash" &&
                                totalPaidCashAmount + parseInt(paymentAmount) >
                                  200000
                              ) {
                                alert(
                                  "Could Not Take more than 200000 in Cash"
                                );
                              } else if (
                                paymentAmount > 200000 &&
                                paymentOptions == "Cash"
                              ) {
                                alert("Could'nt Take more than 200000 in Cash");
                              } else {
                                addPayment();
                              }
                            }}
                          >
                            <GiCheckMark />
                          </button>
                          <button
                            tabindex="6"
                            ref={button5Ref}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                button6Ref.current.focus();
                              }
                            }}
                            onClick={() => {
                              setPaymentAmount(0), setPaymentOptions("Cash");
                            }}
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          textAlign: "left",
                        }}
                        className="adminInviceAddedProductsTotalItemBox"
                      >
                        <label style={{ whiteSpace: "nowrap" }}>
                          Description
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          value={paymentDescription}
                          onChange={(e) =>
                            setPaymentDescription(e.target.value)
                          }
                        />
                        <label>Amount Available</label>
                        {/* <div className="adminInviceAddedProductsAmountInputBox"> */}
                        <input
                          type="text"
                          value={
                            selectedCustomer ? selectedCustomer.advanceAmt : "0"
                          }
                          readOnly
                        />
                        {/* </div> */}
                        <label>Deduct Amount</label>
                        <div className="adminInviceAddedProductsAmountInputBox">
                          <input
                            style={{
                              color:
                                paymentType === "Paid" && paymentAmount !== 0
                                  ? "red"
                                  : paymentType === "Receive" &&
                                    paymentAmount > 0
                                  ? "green"
                                  : "black",
                            }}
                            tabindex="4"
                            ref={button3Ref}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                button4Ref.current.focus();
                              }
                            }}
                            type="number"
                            value={advanceAmount}
                            onChange={(e) => {
                              if (
                                selectedCustomer &&
                                parseFloat(selectedCustomer.advanceAmt) -
                                  parseFloat(e.target.value) >=
                                  0
                              ) {
                                setAdvanceAmount(e.target.value);
                              } else {
                                null;
                              }
                            }}
                          />
                          <button
                            tabindex="5"
                            ref={button4Ref}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                button5Ref.current.focus();
                              }
                            }}
                            onClick={() => {
                              if (
                                paymentOptions == "Cash" &&
                                totalPaidCashAmount + parseInt(paymentAmount) >
                                  200000
                              ) {
                                alert(
                                  "Could Not Take more than 200000 in Cash"
                                );
                              } else if (
                                paymentAmount > 200000 &&
                                paymentOptions == "Cash"
                              ) {
                                alert("Could'nt Take more than 200000 in Cash");
                              } else {
                                addPayment();
                              }
                            }}
                          >
                            <GiCheckMark />
                          </button>
                          <button
                            tabindex="6"
                            ref={button5Ref}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                button6Ref.current.focus();
                              }
                            }}
                            onClick={() => {
                              setPaymentAmount(0), setPaymentOptions("Cash");
                            }}
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
                <div className="adminInviceAddedProductsTotalAmountBox">
                  <table>
                    <thead>
                      <tr>
                        <th>Mode</th>
                        <th>Amount</th>
                        <th>Gold</th>
                        <th>Silver</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.map((payment, index) => (
                        <tr key={index}>
                          <td>{payment.mode}</td>
                          <td>{payment.amount}</td>
                          <td>{payment.fineGold}</td>
                          <td>{payment.fineSilver}</td>
                          {/* Button to delete the payment */}
                          <td onClick={() => deletePayment(index)}>
                            <button
                              tabIndex="7"
                              ref={button6Ref}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  button7Ref.current.focus();
                                }
                              }}
                              className="adminInviceAddedProductsTotalAmountDeleteOption"
                              onClick={() => deletePayment(index)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="adminInviceAddedProductsTotalItemBox">
                <label>Balance Gold (F + W)</label>
                <input
                  type="text"
                  value={parseFloat(totalPayableGold).toFixed(3)}
                  readOnly
                />
                <label>Balance Silver (F + W)</label>
                <input
                  type="text"
                  value={parseFloat(totalPayableSilver).toFixed(3)}
                  readOnly
                />
                <label>Taxable Amount</label>
                <input
                  type="text"
                  value={parseInt(allProdctsNetAmount).toLocaleString("en-IN")}
                  readOnly
                />
                <label>R.O./Discount(-)</label>
                <input
                  type="text"
                  value={parseInt(discountAmount).toLocaleString("en-IN")}
                  readOnly
                />
                <div className="invoiceGstCheckBox1">
                  <label>GST 3% </label>
                  <input
                    // className="invoiceGstCheckBox1"
                    style={{ marginLeft: "10px" }}
                    type="checkbox"
                    checked={gstType}
                    onChange={() => setGstType(!gstType)}
                  />
                </div>
                <input
                  type="text"
                  value={parseInt(totalPayableGstAmount).toLocaleString(
                    "en-IN"
                  )}
                  readOnly
                />

                <label>Total Amount</label>
                <input
                  tabIndex="2"
                  ref={button1Ref}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      button2Ref.current.focus();
                    }
                  }}
                  type="text"
                  style={{ backgroundColor: "wheat" }}
                  value={Math.ceil(totalPayableAmount)}
                  onChange={(e) => {
                    const newTotalPayableAmount = parseFloat(e.target.value);
                    if (!isNaN(newTotalPayableAmount)) {
                      // Check if the input value is a valid number
                      setTotalPayableGstAmount(
                        ((newTotalPayableAmount / 103) * 3).toFixed(2)
                      );
                      changeTotalPrice(e);

                      // setGrandTotal(0);
                      // setOldGoldAmount(0);
                    } else {
                      //   setTotalPayableAmount(allProdctsNetAmount);
                      setTotalPayableAmount(0);
                    }
                  }}
                />
                {/* <label>Purchase Amount</label>
                <input
                  type="text"
                  readOnly
                  value={parseInt(oldGoldAmount)}
                  onChange={(e) => {
                    if (!isNaN(oldGoldAmount)) {
                      setOldGoldAmount(e.target.value),
                        // Check if the input value is a valid number
                        setGrandTotal(
                          parseFloat(
                            parseFloat(totalPayableAmount) -
                              parseFloat(e.target.value)
                          )
                        );
                      setPaymentAmount(
                        parseFloat(
                          parseFloat(totalPayableAmount) -
                            parseFloat(e.target.value)
                        )
                      );
                    } else {
                      // setTotalPayableAmount(allProdctsNetAmount);
                      setGrandTotal(0);
                      setOldGoldAmount(0);
                    }
                  }}
                /> */}
                <label>Paid Amount</label>
                <input type="text" value={parseInt(totalPaidAmount)} readOnly />
                <label>Balance Amount</label>
                <input
                  type="text"
                  value={parseInt(grandTotal).toLocaleString("en-IN")}
                  readOnly
                />
              </div>
            </div>
            {/* <div
                id="adminInvoiceSelectLabelBox"
                className="adminInvoiceSelectLabelBox"
              >
                <div className="adminInvoiceSelectItem">
                  <label>Net Amount</label>
  
                  <input
                    type="text"
                    value={parseInt(allProdctsNetAmount)}
                    readOnly
                  />
                </div>
                <div className="adminInvoiceSelectItem">
                  <label>R.O./Discount(-)</label>
                  <input type="text" value={parseInt(discountAmount)} readOnly />
                </div>
                <div className="adminInvoiceSelectItem">
                  <label>GST 3%</label>
                  <input
                    type="text"
                    value={parseInt(totalPayableGstAmount)}
                    readOnly
                  />
                </div>
                <div className="adminInvoiceSelectItem">
                  <label>Total Amount</label>
                  <input
                    type="text"
                    style={{ backgroundColor: "wheat" }}
                    value={Math.ceil(totalPayableAmount)}
                    onChange={(e) => {
                      const newTotalPayableAmount = parseFloat(e.target.value);
                      if (!isNaN(newTotalPayableAmount)) {
                        // Check if the input value is a valid number
                        setTotalPayableGstAmount(
                          ((newTotalPayableAmount / 103) * 3).toFixed(2)
                        );
                        changeTotalPrice(e);
                      } else {
                        //   setTotalPayableAmount(allProdctsNetAmount);
                        setTotalPayableAmount(0);
                      }
                    }}
                  />
                  <button onClick={() => setUpdatePrices(!updatePrices)}>
                   Update
                    </button>
                </div>
                <div
                  style={{ marginBottom: "0px" }}
                  className="bulkProductAddingTableMain"
                >
                  <button
                    style={{ cursor: "pointer", paddingInline: "100px" }}
                    onClick={() => {
                      if (selectedCustomer && allSelectedProducts.length > 0) {
                        createOrder();
                      } else {
                        alert("Please add all details");
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </div> */}
            <div className="adminInvoiceMainSaveButtonBox">
              <button
                tabIndex="10"
                ref={button9Ref}
                // onKeyPress={(e) => {
                //   if (e.key === "Enter") {
                //     button10Ref.current.focus();
                //   }
                // }}
                style={{ marginInline: "10px" }}
                onClick={() => {
                  if (selectedCustomer && allSelectedProducts.length > 0) {
                    createOrder();
                    // checkPurchaseItems();
                  } else {
                    alert("Please add all details");
                  }
                }}
              >
                Save
              </button>
              <button
                tabIndex="11"
                ref={button10Ref}
                //  onKeyPress={(e) => {
                //    if (e.key === "Enter") {
                //      button1Ref.current.focus();
                //    }
                //  }}
                style={{ marginInline: "10px" }}
                onClick={() => resetAllFields()}
              >
                Reset{" "}
              </button>
              <button
                tabIndex="11"
                ref={button10Ref}
                //  onKeyPress={(e) => {
                //    if (e.key === "Enter") {
                //      button1Ref.current.focus();
                //    }
                //  }}
                style={{ marginInline: "10px" }}
                onClick={() => navigate("/purchase")}
              >
                Purchase List
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
